import getConfig from 'next/config';

import { Roles } from 'app/types/auth';
import { NextGetConfig } from 'app/types/nextjs';
import {
  IAttachmentStorageProvider,
  PortalConfigAclInterface,
  PortalConfigInterface,
  PortalConfigLicense,
} from 'app/types/portalConfig';
import { Routes } from 'app/utils/constants/routes';

const {
  publicRuntimeConfig: { PORTAL_CUSTOMIZATION_CONFIGURATION },
} = getConfig() as NextGetConfig;

const getAclValue = (key: keyof PortalConfigAclInterface, acl: PortalConfigAclInterface) => !!acl?.[key];

const mapConfigToRoutes = (acl: PortalConfigAclInterface) => ({
  [Routes.company.list().pathname]: getAclValue('company', acl),
  [Routes.company.create().pathname]: getAclValue('company', acl),
  [Routes.company.update().pathname]: getAclValue('company', acl),
  [Routes.company.deleted().pathname]: getAclValue('company', acl),
  [Routes.profile.list().pathname]: getAclValue('account', acl),
  [Routes.dashboard.list().pathname]: getAclValue('dashboard', acl),
  [Routes.dashboard.get().pathname]: getAclValue('dashboard', acl),
  [Routes.environment.list().pathname]: getAclValue('environments', acl),
  [Routes.environment.create().pathname]: getAclValue('environments', acl),
  [Routes.environment.update().pathname]: getAclValue('environments', acl),
  [Routes.environment.delete().pathname]: getAclValue('environments', acl),
  [Routes.environment.restore().pathname]: getAclValue('environments', acl),
  [Routes.environment.activateCountry().pathname]: getAclValue('environments', acl),
  [Routes.environment.get().pathname]: getAclValue('environments', acl),
  [Routes.environment.manageConfigs().pathname]: getAclValue('environments', acl),
  [Routes.sdk.list().pathname]: getAclValue('clients', acl),
  [Routes.sdk.create().pathname]: getAclValue('clients', acl),
  [Routes.sdk.update().pathname]: getAclValue('clients', acl),
  [Routes.sdk.delete().pathname]: getAclValue('clients', acl),
  [Routes.service.list().pathname]: getAclValue('integrations', acl),
  [Routes.service.create().pathname]: getAclValue('integrations', acl),
  [Routes.restApi.manageCredentials().pathname]: getAclValue('integrations', acl),
  [Routes.restApi.manageCredentialsCreate().pathname]: getAclValue('integrations', acl),
  [Routes.restApi.manageCredentialsDelete().pathname]: getAclValue('integrations', acl),
  [Routes.restApi.manageCredentialsUpdate().pathname]: getAclValue('integrations', acl),
  [Routes.service.renew().pathname]: getAclValue('integrations', acl),
  [Routes.service.delete().pathname]: getAclValue('integrations', acl),
  [Routes.encryptionKey.list().pathname]: getAclValue('encryptionKeys', acl),
  [Routes.encryptionKey.create().pathname]: getAclValue('encryptionKeys', acl),
  [Routes.encryptionKey.update().pathname]: getAclValue('encryptionKeys', acl),
  [Routes.encryptionKey.rotate().pathname]: getAclValue('encryptionKeys', acl),
  [Routes.member.list().pathname]: getAclValue('members', acl),
  [Routes.member.remove().pathname]: getAclValue('members', acl),
  [Routes.member.resetPassword().pathname]: getAclValue('members', acl),
  [Routes.member.changeRole().pathname]: getAclValue('members', acl),
  [Routes.member.transfer().pathname]: getAclValue('members', acl),
  [Routes.invite.list().pathname]: getAclValue('members', acl),
  [Routes.invite.cancel().pathname]: getAclValue('members', acl),
  [Routes.invite.resend().pathname]: getAclValue('members', acl),
  [Routes.member.invite().pathname]: getAclValue('members', acl),
  [Routes.residentFunction.create().pathname]: getAclValue('residentFunctions', acl),
  [Routes.residentFunction.list().pathname]: getAclValue('residentFunctions', acl),
  [Routes.residentFunction.publish().pathname]: getAclValue('residentFunctions', acl),
  [Routes.residentFunction.configure().pathname]: getAclValue('residentFunctions', acl),
  [Routes.residentFunction.delete().pathname]: getAclValue('residentFunctions', acl),
  [Routes.profile.mfaApp().pathname]: getAclValue('twoFactorAuth', acl),
  [Routes.profile.mfaEmail().pathname]: getAclValue('twoFactorAuth', acl),
  [Routes.border.list().pathname]: getAclValue('borderConfig', acl),
  [Routes.border.create().pathname]: getAclValue('borderConfig', acl),
  [Routes.border.update().pathname]: getAclValue('borderConfig', acl),
  [Routes.border.view().pathname]: getAclValue('borderConfig', acl),
  [Routes.border.import().pathname]: getAclValue('borderConfig', acl),
  [Routes.border.delete().pathname]: getAclValue('borderConfig', acl),
  [Routes.profile.changeEmail().pathname]: getAclValue('account', acl),
  [Routes.paymentVault.create().pathname]: getAclValue('paymentVault', acl),
  [Routes.paymentVault.embed().pathname]: getAclValue('paymentVault', acl),
  [Routes.emailGateway.create().pathname]: getAclValue('emailGateway', acl),
  [Routes.emailGateway.delete().pathname]: getAclValue('emailGateway', acl),
  [Routes.emailGateway.embed().pathname]: getAclValue('emailGateway', acl),
  [Routes.emailGateway.update().pathname]: getAclValue('emailGateway', acl),
  [Routes.firewall.list().pathname]: getAclValue('dataFirewall', acl),
  [Routes.environment.aliases.delete().pathname]: getAclValue('keyMapping', acl),
  [Routes.environment.aliases.list().pathname]: getAclValue('keyMapping', acl),
  [Routes.environment.policies.list().pathname]: getAclValue('policies', acl),
  [Routes.environment.policies.create().pathname]: getAclValue('policies', acl),
  [Routes.environment.policies.get().pathname]: getAclValue('policies', acl),
  [Routes.environment.policies.update().pathname]: getAclValue('policies', acl),
  [Routes.environment.policies.delete().pathname]: getAclValue('policies', acl),
  [Routes.environment.logs().pathname]: getAclValue('environments', acl),
  borderConfigBulk: getAclValue('borderConfigBulk', acl),
  [Routes.email.old().pathname]: getAclValue('account', acl),
  [Routes.email.new().pathname]: getAclValue('account', acl),
  [Routes.ai.create().pathname]: getAclValue('ai', acl),
  [Routes.ai.configure().pathname]: getAclValue('ai', acl),
});

const DEFAULT_CONFIG_ACL = {
  company: true,
  account: true,
  borderConfig: true,
  clients: true,
  dashboard: true,
  emailGateway: true,
  environments: true,
  gettingStarted: true,
  htmlGateway: true,
  logout: true,
  members: true,
  paymentVault: true,
  residentFunctions: true,
  subscription: true,
  twoFactorAuth: true,
  webFormGateway: true,
  dataFirewall: true,
  keyMapping: true,
  policies: true,
  borderConfigBulk: true,
  ai: true,
  encryptionKeys: { typesBlacklist: [] },
  integrations: { typesBlacklist: [] },
  attachmentStorageProvider: {},
};

class PortalConfig {
  assets = 'incountry';
  title = 'InCountry';
  longTitle = 'Data Residency as a Service (DRaaS) by InCountry';
  website = 'https://incountry.com';
  supportTicket = '';
  supportTel = '';
  urlPrivacyPolicy = 'https://incountry.com/legal/privacy-policy/';
  urlTos = 'https://incountry.com/legal/terms-of-service/';
  urlToDocs = 'https://docs.incountry.com/';
  licenses: PortalConfigLicense[] = [];
  acl: { [key: string]: boolean } = mapConfigToRoutes(DEFAULT_CONFIG_ACL);
  encryptionKeysTypesBlacklist: string[] = [];
  attachmentStorageProvider: IAttachmentStorageProvider = {};

  supportEmail?: string = 'support@incountry.com';
  address?: string = '228 Grant Avenue, 5th Floor San Francisco, CA 94108 USA';
  websiteTitle = 'InCountry.com';
  transferAllowedRoles: Roles[] = [Roles.administrator, Roles.manager, Roles.auditor];

  constructor(data: unknown) {
    if (isPortalConfig(data)) {
      this.assets = data.assets;
      this.title = data.title;
      this.longTitle = data.longTitle;
      this.website = data.website;
      this.supportTicket = data.supportTicket;
      this.supportTel = data.supportTel;
      this.urlPrivacyPolicy = data.urlPrivacyPolicy;
      this.urlTos = data.urlTos;
      this.urlToDocs = data.urlToDocs;
      this.licenses = data.licenses;
      this.acl = mapConfigToRoutes(data.acl);
      this.encryptionKeysTypesBlacklist = data?.acl?.encryptionKeys?.typesBlacklist;
      this.attachmentStorageProvider = data.acl.attachmentStorageProvider;

      this.supportEmail = this.isDefault() ? this.supportEmail : undefined;
      this.address = this.isDefault() ? this.address : undefined;
      this.websiteTitle =
        this.isDefault() || this.isDCC() ? this.websiteTitle : data.website.replace(/^https?:\/\//, '');
      this.transferAllowedRoles = data.transferAllowedRoles || this.transferAllowedRoles;
    }
  }

  isDefault = () => this.assets === 'incountry';
  isAliCloud = () => this.assets === 'alicloud';
  isDCC = () => this.assets === 'dcc';
}

const isPortalConfig = (data: unknown): data is PortalConfigInterface => {
  const d = data as PortalConfigInterface;
  return d?.assets !== undefined;
};

const portalConfig = new PortalConfig(PORTAL_CUSTOMIZATION_CONFIGURATION);

export default portalConfig;
