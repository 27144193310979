import EncryptionKey, { EncryptionKeyRequestBody } from 'app/api/models/EncryptionKey';
import Service from 'app/api/models/Service';
import { DEFAULT_ROWS_PER_PAGE } from 'app/components/common/EnhancedTable';
import { FormInputs as CreateCompanyFormValues } from 'app/forms/CreateCompanyForm';
import { FormInputs as EmailGatewayFormInputs } from 'app/forms/CreateEmailGatewayForm';
import { FormInputs as CreateEncryptionKeyFormInputs } from 'app/forms/CreateEncryptionKeyForm';
import { FormInputs as RotateEncryptionKeyFormInputs } from 'app/forms/UpdateEncryptionKeyForm';
import { ICheckCodeResponse } from 'app/types/auth';
import { BorderConfigDataInputForBackend, BorderConfigResponse, BorderConfigV2 } from 'app/types/border';
import { CompanyBaseInterface, CompanyUpdateResponse } from 'app/types/company';
import { CountriesResponseInterface, CountryCode, CountryResponseInterface } from 'app/types/country';
import {
  ICountryDataV2,
  IUsageDataByEnvV2,
  UsageTotalsInterface,
  UsageTotalsResponseInterface,
} from 'app/types/dashboard';
import {
  CreateEmailGatewayInterface,
  IEmailGatewayResponse,
  UpdateEmailGatewayInterface
} from 'app/types/email-gateways';
import { EncryptionKeyResponse } from 'app/types/encryption';
import {
  CreateEnvironmentRequest,
  CreateEnvironmentResponse,
  EnvironmentResponseData,
  EnvironmentTypes,
  IEnvironmentFirewallResponse,
  IPolicyResponse,
  ISchema,
  ISchemaResponse,
  ISdk,
  SdkResponse
} from 'app/types/environments';
import { CountryTenant, InfraJsonCountry } from 'app/types/infraJson';
import { ILogServicesData, ILogs, ILogsQuery } from 'app/types/logs';
import {
  InviteMemberRequest,
  InviteMemberResponse,
  InviteResponseInterface,
  MemberResponseInterface,
} from 'app/types/members';
import {
  GetServerSidePropsResult,
  NextGetConfig,
  RouterQueryItem,
  ServerSidePropsContextType,
  isNotFoundResultSsr,
  isPropsResultSsr,
  isRedirectResultSsr,
} from 'app/types/nextjs';
import {
  CreatePaymentVaultBodyInterface,
  CreatePaymentVaultInterface,
  PaymentProviderInterface,
  PaymentVaultInterface,
} from 'app/types/payment-vault';
import { ResidentFunctionResponseInterface, ResidentFunctionsCacheRequestMethods, ResidentFunctionsResponseInterface, ResidentFunctionsResponseMetaInterface } from 'app/types/residentFunctions';
import { AiServiceConfig, ServiceResponse, ServiceTypeFilter, ServiceTypeResponse, ServiceTypesEnum } from 'app/types/services';
import { TableSortOrder } from 'app/types/table';
import { CurrentUserResponse } from 'app/types/user';
import { convertResponseAliasesForBackend, convertResponseAliasesForForm } from 'app/utils/aliases';
import { convertBorderConfigForBackend, convertBorderConfigForForm } from 'app/utils/border';
import { COUNTRY_HEADER, Routes } from 'app/utils/constants';
import { HttpMethod } from 'app/utils/constants/http';
import { COOKIES_TYPES, cookiesService } from 'app/utils/cookies';
import { convertEmailGatewayFormDataToBackendPayload } from 'app/utils/emailGateway';
import { prepareNewEncryptionKeyPayload } from 'app/utils/encryptionKeys';
import { isProduction } from 'app/utils/environmentVariables';
import { ServerErrorInterface } from 'app/utils/errors';
import { downloadTextFile } from 'app/utils/files';
import { isBrowser } from 'app/utils/misc';
import { parseQueryParam } from 'app/utils/next/router';
import { formatISO } from 'date-fns';
import { GetServerSidePropsContext } from 'next';
import getConfig from 'next/config';
import { ParsedUrlQuery } from 'querystring';
import { CurrentUser } from './models/CurrentUser';
import Environment from './models/Environment';
import { EventCorrId, EventStatus, logServerSideEvent } from './models/Event';
import { default as PortalConfig, default as portalConfig } from './models/PortalConfig';
import ResidentFunctions from './models/ResidentFunctions';

const {
  publicRuntimeConfig: { APP_LOG_LEVEL, IC_WD_SERVER_API = 'http://localhost:5000' },
} = getConfig() as NextGetConfig;

const ENDPOINTS = {
  environments: '/environments',
};

class ApiClient {
  private headers: Record<string, string> = {};
  private countryCode?: string;

  constructor() {
    this.headers = {
      'Content-Type': 'application/json',
    };
  }

  setCountryCodeHeader(countryCode?: string) {
    if (!countryCode) return;
    this.countryCode = countryCode;
  }

  private serialize = (obj: Record<string, unknown>, prefix?: string): string => {
    const str = [];
    let p;
    for (p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        const k = prefix ? prefix + '[' + p + ']' : p;
        const v = obj[p] as Record<string, unknown>;
        str.push(
          v !== null && typeof v === 'object'
            ? this.serialize(v, k)
            : encodeURIComponent(k) + '=' + encodeURIComponent(v as string),
        );
      }
    }
    return str.join('&');
  };

  private url(path: string, query?: Record<string, unknown>): string {
    const url = new URL(`${IC_WD_SERVER_API}${path}`);
    if (query) {
      url.search = this.serialize(query);
    }
    return url.toString();
  }

  private getCsrfToken = (ctx?: ServerSidePropsContextType) => {
    if (isBrowser) {
      return cookiesService.getCookie(COOKIES_TYPES.CSRF, document.cookie);
    } else if (ctx?.req?.headers?.cookie) {
      return cookiesService.getCookie(COOKIES_TYPES.CSRF, ctx.req.headers.cookie);
    }
  };

  private async request<T>(url: string, options: RequestInit, ctx?: ServerSidePropsContextType): Promise<T> {
    const headers = {
      ...this.headers,
      ...options.headers,
      [COUNTRY_HEADER]: this.countryCode || '',
    } as { [key: string]: string };

    const csrfToken = this.getCsrfToken();
    if (csrfToken) headers['X-CSRF-TOKEN'] = csrfToken;
    if (ctx) headers['Cookie'] = ctx.req.headers.cookie as string;
    if (ctx) headers[EventCorrId.internal] = ctx.req.headers[EventCorrId.internal] as string;
    if (ctx) headers[EventCorrId.external] = ctx.req.headers[EventCorrId.external] as string;

    const request = new Request(url, {
      ...options,
      credentials: isProduction() ? 'same-origin' : 'include',
      headers,
    });

    const eventData = {
      severity: APP_LOG_LEVEL,
      method: options.method,
      url,
    }

    logServerSideEvent({ ...eventData, status: EventStatus.start }, ctx);

    const response = await fetch(request);

    const cookie = response.headers.get('set-cookie');

    if (ctx && cookie) {
      ctx.res?.setHeader('set-cookie', cookie);
    }

    if (response.ok) {
      if (response.status === 204) {
        return undefined as never;
      }
      return response
        .json()
        .then(data => {
          const payload = data as T;
          logServerSideEvent({ ...eventData, status: EventStatus.success }, ctx);
          return payload;
        })
        .catch(() => {
          const r = response as unknown as T;
          logServerSideEvent({ ...eventData, status: EventStatus.failure }, ctx);
          return r;
        });
    } else if (response.status === 452) {
      if (ctx) return undefined as never;
      return new Promise(res => setTimeout(() => res(this.request(url, options)), 3000));
    } else {
      logServerSideEvent({ ...eventData, status: EventStatus.failure }, ctx);
      throw response;
    }
  }

  private async get<T>(
    path: string,
    query: Record<string, unknown> = {},
    options?: RequestInit,
    ctx?: ServerSidePropsContextType,
  ): Promise<T> {
    const url = this.url(path, query);
    const config = { method: HttpMethod.Get, ...options };
    return this.request<T>(url, config, ctx);
  }

  private async post<T, U>(
    path: string,
    body?: T,
    options?: RequestInit,
    ctx?: ServerSidePropsContextType,
  ): Promise<U> {
    const url = this.url(path);
    const config = { method: HttpMethod.Post, body: JSON.stringify(body), ...options };
    return this.request<U>(url, config, ctx);
  }

  private async put<T, U>(path: string, body?: T, options?: RequestInit): Promise<U> {
    const url = this.url(path);
    const config = { method: HttpMethod.Put, body: JSON.stringify(body), ...options };
    return this.request<U>(url, config);
  }

  private async patch<T, U>(path: string, body?: T, options?: RequestInit): Promise<U> {
    const url = this.url(path);
    const config = { method: HttpMethod.Patch, body: JSON.stringify(body), ...options };
    return this.request<U>(url, config);
  }

  private async delete<T>(path: string, body?: T, options?: RequestInit): Promise<T> {
    const url = this.url(path);
    const config = { method: HttpMethod.Delete, body: JSON.stringify(body), ...options };
    return this.request<T>(url, config);
  }

  public fetchAuthProviderRedirectUrl = async (providerId?: string) => {
    const query = providerId ? { identity_provider: providerId } : undefined;
    const response = await this.get<{ redirectUrl: string }>('/auth/v2/login', query);
    return response.redirectUrl;
  };

  public createNewOrg = async (name: string, countryCode: string) => {
    const data = { name, country: countryCode };
    return this.post<{ name: string; country: string }, unknown>('/company', data);
  };

  public createEnvironment = (type: string, name: string) => {
    return this.post<CreateEnvironmentRequest, CreateEnvironmentResponse>(ENDPOINTS.environments, {
      type,
      name,
    });
  };

  public activateEnvironmentCountry = (id: string, countryCode: CountryCode, tenant: CountryTenant, increment: string) => {
    return this.post<object, void>(`/environments/${id}/${countryCode}/${increment}/${tenant}/activate`, {});
  };

  public updateEnvironment = async (environmentId: string, type: EnvironmentTypes, name: string, firewall?: IEnvironmentFirewallResponse, dtk?: string, hide_keys?: boolean) => {
    const payload = {
      type,
      name,
      firewall,
      ...(dtk ? { dtk } : {}),
      ...(firewall ? { firewall } : {}),
      ...(hide_keys !== undefined ? { hide_keys } : {})
    };
    return this.patch<CreateEnvironmentRequest, CreateEnvironmentResponse>(`/environments/${environmentId}`, payload);
  };

  public restoreEnvironment = async (id: string) => {
    return this.post<object, CreateEnvironmentResponse>(`/environments/${id}/restore`, {});
  };

  public deleteEnvironment = async (environmentId: RouterQueryItem) => {
    if (environmentId) {
      const id = environmentId.toString();
      return this.delete(`/environments/${id}`);
    }
  };

  public createSdk = async (envId: string, name: string, policyExternalId: string, serviceId?: string) => {
    type Payload = { name: string; policy_external_id: string; service_id?: string };
    const response = await this.post<Payload, SdkResponse>(
      `/environments/${envId}/clients`,
      {
        name,
        policy_external_id: policyExternalId,
        service_id: serviceId,
      }
    );
    return response;
  };

  public updateSdk = async (envId: string, sdkId: string, name: string) => {
    return this.patch<{ name: string }, unknown>(`/environments/${envId}/clients/${sdkId}`, { name });
  };

  public deleteSdk = async (envId: string, sdkId: string) => {
    return this.delete<unknown>(`/environments/${envId}/clients/${sdkId}`);
  };

  public fetchServiceCountries = (filter?: ServiceTypeFilter): Promise<InfraJsonCountry[]> => {
    const payload = filter ? { filter } : undefined;
    return this.get<InfraJsonCountry[]>('/infra', payload);
  }

  public createAlias = async (envId: string, schema: ISchema[]) => {
    const response = await this.post<ISchemaResponse, { data: unknown }>(
      `/environments/${envId}/aliases`,
      convertResponseAliasesForBackend(schema),
    );
    return response?.data;
  };

  public deleteAlias = async (envId: string) => {
    return this.delete<unknown>(`/environments/${envId}/aliases`);
  };

  public createAccessPolicy = async (envId: string, policy: IPolicyResponse) => {
    const response = await this.post<IPolicyResponse, { data: unknown }>(
      `/environments/${envId}/policies`,
      policy,
    );
    return response?.data;
  };

  public updateAccessPolicy = async (envId: string, policy: IPolicyResponse) => {
    const response = await this.put<IPolicyResponse, { data: unknown }>(
      `/environments/${envId}/policies/${policy?.external_id}`,
      policy,
    );
    return response?.data;
  };

  public deleteAccessPolicy = async (envId: string, policyExternalId: string) => {
    return this.delete<unknown>(`/environments/${envId}/policies/${policyExternalId}`);
  };

  public deleteService = async (envId: string, serviceId: string) => {
    return this.delete<unknown>(`/environments/${envId}/services/${serviceId}`);
  };

  public createService = async (
    envId: string,
    serviceTypeId: string,
    name: string,
    countryCode: string,
    tenancy: string,
    increment: string,
    oss?: boolean,
    aclEndpoint?: string,
    policyExternalId?: string,
    aiConfig?: AiServiceConfig,
  ) => {
    const data = {
      name,
      country_code: countryCode.toLowerCase(),
      customer_code: tenancy,
      increment,
      type_id: serviceTypeId,
      oss_enabled: oss,
      ...(aclEndpoint ? { aclEndpoint } : {}),
      ...(policyExternalId ? { policy_external_id: policyExternalId } : {}),
      ...(aiConfig ? { ai_config: aiConfig } : {}),
    };
    const response = await this.post<unknown, ServiceResponse>(`/environments/${envId}/services`, data);
    return new Service(response);
  };

  public updateService = async (envId: string, serviceId: string, oss?: boolean, policyExternalId?: string, aiConfig?: AiServiceConfig,) => {
    const data = {
      oss_enabled: oss,
      ...(policyExternalId ? { policy_external_id: policyExternalId } : {}),
      ...(aiConfig ? { ai_config: aiConfig } : {}),
    };
    const response = await this.patch<unknown, ServiceResponse>(
      `/environments/${envId}/services/${serviceId}`,
      data,
    );
    return new Service(response);
  };

  public renewService = async (envId: string, serviceId: string) => {
    const data = { action: 'credentials' };
    const response = await this.patch<unknown, ServiceResponse>(
      `/environments/${envId}/services/${serviceId}`,
      data,
    );
    return new Service(response);
  };

  public cacheResidentFunctions = async (envId: string, serviceId: string, residentFunctions: ResidentFunctionsResponseInterface): Promise<ResidentFunctions> => {
    const data = { action: ResidentFunctionsCacheRequestMethods.rfCache, data: residentFunctions.data, meta: residentFunctions.meta };
    const response = await this.post<{ action: string, data: ResidentFunctionResponseInterface[], meta: ResidentFunctionsResponseMetaInterface }, ServiceResponse>(
      `/environments/${envId}/services/${serviceId}/actions`,
      data,
    );
    return new ResidentFunctions(response);
  };

  public fetchResidentFunctionsCache = async (envId: string, serviceId: string): Promise<ResidentFunctions> => {
    const data = { action: ResidentFunctionsCacheRequestMethods.rfCacheGet };
    const response = await this.post<{ action: string }, ResidentFunctionsResponseInterface>(
      `/environments/${envId}/services/${serviceId}/actions`,
      data,
    );

    return new ResidentFunctions(response);
  };

  public uploadSalesforceCertificate = async (envId: string, serviceId: string, file: File) => {
    const body = new FormData();
    body.append('file', file, file.name);

    const requestOptions = {
      method: HttpMethod.Post,
      headers: this.getCustomHeaders(),
      body,
      redirect: 'follow' as RequestRedirect,
    };

    const response = await fetch(
      `${IC_WD_SERVER_API}/environments/${envId}/services/${serviceId}/upload-cert`,
      requestOptions,
    );

    if (response.ok) {
      return (await response?.json()) as { message: string };
    } else {
      throw response;
    }
  };

  public createEncryptionKey = async (
    envId: string,
    countryCode: string,
    tenant: CountryTenant,
    increment: string,
    params: CreateEncryptionKeyFormInputs,
  ) => {
    const preparedParams = prepareNewEncryptionKeyPayload(envId, countryCode, tenant, params);
    const data = await this.post<EncryptionKeyRequestBody, EncryptionKeyResponse>(
      `/v2/environments/${envId}/secret_keys/${countryCode}/${increment}/${tenant}/create`,
      preparedParams,
    );
    return new EncryptionKey(data);
  };

  public rotateEncryptionKey = async (
    envId: string,
    countryCode: string,
    tenant: CountryTenant,
    increment: string,
    params: RotateEncryptionKeyFormInputs,
  ) => {
    const preparedParams = prepareNewEncryptionKeyPayload(envId, countryCode, tenant, params);
    const data = await this.post<EncryptionKeyRequestBody, EncryptionKeyResponse>(
      `/v2/environments/${envId}/secret_keys/${countryCode}/${increment}/${tenant}/rotate`,
      preparedParams,
    );
    return new EncryptionKey(data);
  };

  public updateEncryptionKey = async (
    envId: string,
    countryCode: string,
    name: string,
    rotationPeriod: number,
    tenant: CountryTenant,
    increment: string,
  ) => {
    const payload = {
      name: name,
      rotation_period: rotationPeriod,
    };
    return this.patch<{ name: string; rotation_period: number }, EncryptionKeyResponse>(
      `/v2/environments/${envId}/secret_keys/${countryCode}/${increment}/${tenant}`,
      payload,
    );
  };

  public updateCompanySettings = (values: CreateCompanyFormValues): Promise<CompanyUpdateResponse> => {
    return this.patch<CompanyBaseInterface, CompanyUpdateResponse>('/company', {
      name: values?.name,
      country: values?.countryCode,
      alerting: values?.emailNotifications,
    });
  };

  public deleteCompany = () => {
    return this.post<object, void>('/company/delete', {});
  };

  public restoreCompany = () => {
    return this.post<object, void>('/company/restore', {});
  };

  public confirmEmail = (token: string) => {
    return this.get<Response>(`/confirm-email/${token}`);
  };

  public changeEmail = (email: string, recaptcha: string) => {
    return this.post<{ email: string; recaptcha: string }, unknown>('/email/change', { email, recaptcha });
  };

  public confirmOldEmail = (token: string) => {
    return this.post<{ token: string }, Response>('/email/confirm/old', { token });
  };

  public confirmNewEmail = (token: string) => {
    return this.post<{ token: string }, Response>('/email/confirm/new', { token });
  };

  public removeMember = (memberId: string) => {
    return this.delete<void>(`/users/${memberId}`);
  };

  public changeMemberRole = (memberId: string, role: string) => {
    return this.patch<{ role: string }, void>(`/users/${memberId}`, { role });
  };

  public transferMemberOwnership = (memberId: string, password: string) => {
    return this.patch(`/users/${memberId}/ownership`, { password });
  };

  public inviteMember = (email: string, recaptcha: string, resend?: boolean) => {
    return this.post<InviteMemberRequest, InviteMemberResponse>('/invites', {
      email,
      resend: !!resend,
      recaptcha,
    });
  };

  public cancelInvite = (token: string) => {
    return this.delete<void>(`/invites/${token}`);
  };

  public createBorderConfigV2 = (
    envId: string,
    serviceId: string,
    configData: BorderConfigV2,
    countryCode: string,
    schema: ISchema[],
  ) => {
    const cfg = convertBorderConfigForBackend(configData, countryCode, schema);
    return this.post<BorderConfigDataInputForBackend, BorderConfigV2>(
      `/environments/${envId}/services/${serviceId}/border_cfg`,
      cfg,
    );
  };

  public updateBorderConfigV2 = (
    envId: string,
    serviceId: string,
    borderConfigId: string,
    configData: BorderConfigV2,
    countryCode: string,
    schema: ISchema[],
  ) => {
    const cfg = convertBorderConfigForBackend(configData, countryCode, schema);
    return this.patch<BorderConfigDataInputForBackend, BorderConfigV2>(
      `/environments/${envId}/services/${serviceId}/border_cfg/${borderConfigId}`,
      cfg,
    );
  };

  public deleteBorderConfigV2 = async (envId: string, serviceId: string, borderConfigId: string) => {
    return await this.delete<void>(`/environments/${envId}/services/${serviceId}/border_cfg/${borderConfigId}`);
  };

  public createPaymentGateway = async (
    environmentId: string | null,
    countryCode: string,
    tenancy: CountryTenant,
    increment: string,
    paymentProvider: string,
    paymentGatewayName: string,
    paymentGatewayEndpoint: string,
    paymentGatewayMethod: string,
    paymentProviderType?: string,
  ) => {
    const body = {
      payment_provider_id: paymentProvider,
      environment_id: environmentId,
      title: paymentGatewayName,
      app_endpoint: paymentGatewayEndpoint,
      country: countryCode,
      customer_code: tenancy,
      increment,
      request_method: paymentGatewayMethod,
    };

    return this.post<CreatePaymentVaultBodyInterface, CreatePaymentVaultInterface>(
      `/environments/${environmentId}/pvs`,
      body,
    );
  };

  public deletePaymentGateway = async (envId: string, id: string) => {
    return this.delete(`/environments/${envId}/pvs/${id}`);
  };

  public deleteEmailGateway = (envId: string, id: string) => {
    const data = { email_gateway_id: id };
    return this.delete(`/environments/${envId}/egs/${id}`, data);
  };

  public createEmailGateway = async (envId: string, values: EmailGatewayFormInputs) => {
    const body = convertEmailGatewayFormDataToBackendPayload(values);

    return this.post<typeof body, CreateEmailGatewayInterface>(`/environments/${envId}/egs`, body);
  };

  public updateEmailGateway = async (envId: string, id: string, values: EmailGatewayFormInputs) => {
    const body = convertEmailGatewayFormDataToBackendPayload(values);

    return this.patch<typeof body, UpdateEmailGatewayInterface>(`/environments/${envId}/egs/${id}`, body);
  };

  public logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace(`${IC_WD_SERVER_API}/auth/v2/logout`);
  };

  public fetchConfirmationStatus = async () => {
    const response = await this.get<{ ttl: number }>('/auth/v2/login/status');
    return response?.ttl;
  };

  public sendConfirmTokenToEmail = () => {
    return this.post('/auth/code', {});
  };

  public exchangeConfirmCodeToToken = (code: string, useRecoveryCode?: boolean) => {
    const data = { token: code };
    const path = useRecoveryCode ? '/auth/lookup/verify/within' : '/auth/code/check';
    return this.post<{ token: string }, ICheckCodeResponse>(path, data);
  };

  private TOTP_ENDPOINT = '/auth/totp';

  public fetchTotpSeed = async () => {
    const { totp_seed } = await this.get<{ totp_seed: string }>(this.TOTP_ENDPOINT);
    return totp_seed;
  };

  public enableTotp = async (code: string) => {
    return await this.post<{ token: string }, { msg: string }>(this.TOTP_ENDPOINT, { token: code });
  };

  public disableTotp = async () => {
    return await this.delete(this.TOTP_ENDPOINT, {});
  };

  public fetchRecoveryCodes = async () => {
    const response = await this.post<unknown, { lookup_secrets: string[] }>('/auth/lookup', {});
    return response?.lookup_secrets;
  };

  public fetchPasswordChallenge = async (): Promise<string | undefined> => {
    const response = await this.get<{ redirect: string }>('/auth/password/challenge');
    return response?.redirect;
  };

  public resetMemberPassword = (orgId: RouterQueryItem, userId: string, email: string) => {
    if (!orgId) return;
    const data = { email, user_uuid: userId, company_uuid: orgId?.toString() };
    return this.post<{ email: string; company_uuid: string }, void>('/auth/password/change/request', data);
  };

  public exportEnvironmentConfig = async (envId: string) => {
    const res = await this.get<unknown>(`/environments/${envId}/export`);
    downloadTextFile(JSON.stringify(res), `${envId}.json`);
  };

  public createEnvConfigCredentials = (envId: string) => {
    return this.post<{}, ISdk>(`/environments/${envId}/clients/migration`, {});
  };

  public renewEnvConfigCredentials = (envId: string) => {
    return this.patch<{}, ISdk>(`/environments/${envId}/clients/migration`, {});

  };

  public getCustomHeaders = () => {
    const headers = new Headers();
    headers.append(COUNTRY_HEADER, this.countryCode || '');
    headers.append('X-CSRF-TOKEN', cookiesService.getCookie(COOKIES_TYPES.CSRF, document.cookie) || '');

    return headers;
  }

  public importEnvironmentConfig = async (envId: string, file: File) => {
    const body = new FormData();
    body.append('file', file, file.name);

    const requestOptions = {
      method: HttpMethod.Post,
      headers: this.getCustomHeaders(),
      body,
      redirect: 'follow' as RequestRedirect,
    };

    const response = await fetch(
      `${IC_WD_SERVER_API}/environments/${envId}/import`,
      requestOptions,
    );

    if (!response.ok) {
      throw response;
    }

    return response;
  };

  // SSR section
  public checkSsr = <T>(
    func: (ctx: ServerSidePropsContextType, obj?: { props: T }, user?: CurrentUser) => Promise<GetServerSidePropsResult<T>>,
  ) => {
    return (ctx: ServerSidePropsContextType, obj?: GetServerSidePropsResult<unknown>, user?: CurrentUser) => {
      if (isRedirectResultSsr(obj)) {
        return { redirect: obj.redirect };
      }
      if (isNotFoundResultSsr(obj)) {
        return { notFound: obj.notFound };
      }
      if (isPropsResultSsr<T>(obj)) {
        return func(ctx, obj, user);
      }
      return func(ctx, undefined, user);
    };
  };

  public fetchUserSsr = async (ctx: GetServerSidePropsContext): Promise<CurrentUserResponse> => {
    const res = await this.get<CurrentUserResponse>('/me', undefined, undefined, ctx);
    return { ...res, $TypeID: 'CurrentUser' };
  };

  public logoutSsr = (errorStatus?: number) => {
    const redirectUrl = new URL(`${IC_WD_SERVER_API}/auth/v2/logout`);
    if (errorStatus) redirectUrl.searchParams.append('errorStatus', errorStatus.toString());
    return {
      redirect: {
        permanent: false,
        destination: redirectUrl.toString(),
      },
    };
  };

  public ssrErrorHandler = async <T>(err: unknown): Promise<GetServerSidePropsResult<T>> => {
    if (err instanceof Error) {
      return {
        redirect: {
          permanent: false,
          destination: `/500?name=${err.name}&message=${err.message}&stack=${err.stack}`,
        },
      };
    } else if (err instanceof Response) {
      const resp = (await err.json()) as ServerErrorInterface;
      const error = resp?.errors?.[0];
      return {
        redirect: {
          permanent: false,
          destination: `/500?code=${error?.status}&name=${error?.title}&message=${error?.source}&url=${err?.url}&corId=${err?.headers?.get(EventCorrId.internal)}`,
        },
      };
    } else {
      throw err;
    }
  };

  public fetchPaymentProvidersSsr = this.checkSsr<{ paymentProviders: PaymentProviderInterface[] }>(
    async (ctx, obj) => {
      try {
        const response = await this.get<PaymentProviderInterface[]>(
          '/pvs/payment_providers',
          undefined,
          undefined,
          ctx,
        );
        return { ...obj, props: { ...obj?.props, paymentProviders: response } };
      } catch (err) {
        return this.ssrErrorHandler(err);
      }
    },
  );

  public fetchPaymentVaultsSsr = this.checkSsr<{ paymentVaults: PaymentVaultInterface[] }>(async (ctx, obj) => {
    try {
      if (!portalConfig.acl?.[Routes.paymentVault.create().pathname]) return { ...obj, props: { ...obj?.props, paymentVaults: [] } };

      const envId = parseQueryParam(ctx.query.envId);
      if (!envId) {
        const envs = (obj?.props?.environments as EnvironmentResponseData[])?.filter(v => !v.removed_at)?.filter(v => v?.countries?.filter(v => v.activated)?.length > 0);
        const envsPromises = await Promise.all((envs || []).map(async env => {
          return await this.get<PaymentVaultInterface[]>(`/environments/${env?.id}/pvs`, undefined, undefined, ctx)
        }));

        return { ...obj, props: { ...obj?.props, paymentVaults: envsPromises?.flatMap(v => v) } };
      }

      const response = await this.get<PaymentVaultInterface[]>(`/environments/${envId}/pvs`, undefined, undefined, ctx);
      return { ...obj, props: { ...obj?.props, paymentVaults: response } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchUsageTotalsByEnvSsr = this.checkSsr<{ usageTotalsData: UsageTotalsInterface[] }>(async (ctx, obj) => {
    try {
      const envId = parseQueryParam(ctx.query.envId);
      const startDate = parseQueryParam(ctx.query.startDate);
      if (!envId) throw new Error('Environment ID parameter is missing');
      if (!startDate) throw new Error('Start date parameter is missing');
      const data = { last_update_time: formatISO(new Date(startDate))?.split('+')?.[0] };
      const response = await this.get<UsageTotalsResponseInterface>(
        `/environments/${envId}/usage/totals`,
        data,
        undefined,
        ctx,
      );

      return { ...obj, props: { ...obj?.props, usageTotalsData: response?.data } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchUsageByEnv = async (envId: string, startDate: string, endDate: string) => {
    const data = { start_time: new Date(startDate)?.toISOString()?.split('+')?.[0], end_time: new Date(endDate)?.toISOString()?.split('+')?.[0] };
    const response = await this.get<IUsageDataByEnvV2>(`/environments/${envId}/usage`, data);

    return response?.data;
  }

  public fetchUsageByEnvSsr = this.checkSsr<{ usage: ICountryDataV2[] }>(async (ctx, obj) => {
    try {
      const envId = obj?.props?.envId || parseQueryParam(ctx.query.envId);
      const startDate = obj?.props?.startDate || parseQueryParam(ctx.query.startDate);
      const endDate = obj?.props?.endDate || parseQueryParam(ctx.query.endDate);
      if (!envId) throw new Error('Environment ID parameter is missing');
      if (!startDate) throw new Error('Start date parameter is missing');
      if (!endDate) throw new Error('End date parameter is missing');
      const data = { start_time: new Date(startDate)?.toISOString()?.split('+')?.[0], end_time: new Date(endDate)?.toISOString()?.split('+')?.[0] };

      const response = await this.get<IUsageDataByEnvV2>(
        `/environments/${envId}/usage`,
        data,
        undefined,
        ctx,
      );
      return { ...obj, props: { ...obj?.props, usage: response?.data } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchUsageByCompany = async (startDate: string, endDate: string) => {
    const data = { start_time: new Date(startDate)?.toISOString()?.split('+')?.[0], end_time: new Date(endDate)?.toISOString()?.split('+')?.[0] };
    const response = await this.get<IUsageDataByEnvV2>(`/company/usage`, data);

    return response?.data;
  }

  public fetchEnvironmentsSsr = this.checkSsr<{ environments: EnvironmentResponseData[] }>(async (ctx, obj, user) => {
    try {
      if (user?.isAccessDenied?.(Routes.environment.list().pathname)) {
        return { ...obj, props: { environments: [] } }
      };
      let environments = await this.get<EnvironmentResponseData[]>(ENDPOINTS.environments, undefined, undefined, ctx);
      environments = environments?.map(env => ({ ...env, $TypeID: 'Environment' }));
      return { ...obj, props: { ...obj?.props, environments: environments?.filter(v => !v.removed_at) } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchSdksSsr = this.checkSsr<{ sdkData: SdkResponse }>(async (ctx, obj) => {
    try {
      const envId = ctx?.query?.envId;
      if (!envId) throw new Error('Environment ID is not defined');
      const sdkData = await this.get<SdkResponse>(`/environments/${envId}/clients`, undefined, undefined, ctx);

      return { ...obj, props: { ...obj?.props, sdkData } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEnvironments = async () => {
    const environments = await this.get<EnvironmentResponseData[]>(ENDPOINTS.environments);

    return environments?.filter(v => !v.removed_at)?.map(v => new Environment(v));
  };

  public fetchWithRemovedEnvironmentsSsr = this.checkSsr<{ environments: EnvironmentResponseData[] }>(
    async (ctx, obj) => {
      try {
        let environments = await this.get<EnvironmentResponseData[]>(ENDPOINTS.environments, undefined, undefined, ctx);
        environments = environments?.map(env => ({ ...env, $TypeID: 'Environment' }));
        return { ...obj, props: { ...obj?.props, environments } };
      } catch (err) {
        return this.ssrErrorHandler(err);
      }
    },
  );

  public fetchEnvironmentSsr = this.checkSsr<{ environment: EnvironmentResponseData }>(async (ctx, obj) => {
    try {
      const envId = ctx?.query?.envId;
      if (!envId) throw new Error('Environment ID is not defined');

      const environment = await this.get<EnvironmentResponseData>(
        `/environments/${envId.toString()}`,
        undefined,
        undefined,
        ctx,
      );
      environment.$TypeID = 'Environment';

      return { ...obj, props: { ...obj?.props, environment } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEnvironmentFirewallSsr = this.checkSsr<{ environmentFirewall: IEnvironmentFirewallResponse }>(async (ctx, obj) => {
    try {
      const envId = ctx?.query?.envId;
      if (!envId) throw new Error('Environment ID is not defined');

      const environmentFirewall = await this.get<IEnvironmentFirewallResponse>(
        `/environments/${envId.toString()}/firewall`,
        undefined,
        undefined,
        ctx,
      );

      return { ...obj, props: { ...obj?.props, environmentFirewall: Object.values(environmentFirewall || {}).length === 0 ? {} : environmentFirewall?.firewall } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchRecoveryCodesSsr = this.checkSsr<{ recoveryCodes: string[] }>(async (ctx, obj) => {
    try {
      const response = await this.post<unknown, { lookup_secrets: string[] }>('/auth/lookup', {}, undefined, ctx);

      return { ...obj, props: { ...obj?.props, recoveryCodes: response.lookup_secrets } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchMembersSsr = this.checkSsr<{ members: MemberResponseInterface[] }>(async (ctx, obj) => {
    try {
      const response = await this.get<{ users: MemberResponseInterface[] }>('/users', undefined, undefined, ctx);
      const members = response?.users?.map(v => ({ ...v, $TypeID: 'Member' }));
      return { ...obj, props: { ...obj?.props, members } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchInvitesSsr = this.checkSsr<{ invites: InviteResponseInterface[] }>(async (ctx, obj, user) => {
    try {
      if (user?.isAccessDenied?.(Routes.invite.list().pathname)) {
        return { ...obj, props: { invites: [] } }
      };
      const response = await this.get<{ invites: InviteResponseInterface[] }>('/invites', undefined, undefined, ctx);
      const invites = response?.invites?.map(v => ({ ...v, $TypeID: 'Invite' }));
      return { ...obj, props: { ...obj?.props, invites } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchBorderConfigsSsr = this.checkSsr<{ borderConfigs: BorderConfigV2[], environments: EnvironmentResponseData[] }>(async (ctx, obj) => {
    try {
      const envId = parseQueryParam(ctx.query.envId);
      let serviceId = parseQueryParam(ctx.query.serviceId);

      const emptyState = { ...obj, props: { ...obj?.props, borderConfigs: [] } };

      const envs = obj?.props?.environments as EnvironmentResponseData[];

      if (!envId || !envs) return emptyState;

      const env = envs?.find(e => e.id === envId) as EnvironmentResponseData;

      if (serviceId) {
        const serviceCode = env?.services?.find(s => s.id === serviceId)?.type?.code;
        if (serviceCode !== ServiceTypesEnum.border) {
          return emptyState;
        }
      }

      if (!serviceId) {
        serviceId = env?.services?.find(s => s.type.code === ServiceTypesEnum.border)?.id;
        if (!serviceId) return emptyState;
      }

      const schema = obj?.props?.schema as ISchema[];

      const response = await this.get<BorderConfigResponse[]>(
        `/environments/${envId}/services/${serviceId}/border_cfg`,
        undefined,
        undefined,
        ctx,
      );

      const borderConfigs = response?.map(config => convertBorderConfigForForm(config, schema));
      return { ...obj, props: { ...obj?.props, borderConfigs } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchBorderConfigs = async (envId: string, serviceId: string) => {
    return this.get<BorderConfigResponse[]>(`/environments/${envId}/services/${serviceId}/border_cfg`);
  };

  public fetchBorderConfigSsr = this.checkSsr<{ borderConfig: BorderConfigV2 }>(async (ctx, obj) => {
    try {
      const serviceId = parseQueryParam(ctx.query.serviceId);
      const envId = parseQueryParam(ctx.query.envId);
      const configurationId = parseQueryParam(ctx.query.configurationId);

      const response = await this.get<BorderConfigResponse>(
        `/environments/${envId}/services/${serviceId}/border_cfg/${configurationId}`,
        undefined,
        undefined,
        ctx,
      );
      const borderConfig = convertBorderConfigForForm(response);
      return { ...obj, props: { ...obj?.props, borderConfig } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public getLogsQuery = (queryParams: ParsedUrlQuery, downloadRequest?: boolean): ILogsQuery => {
    const countryCodeQuery = parseQueryParam(queryParams.countryCode) as string;
    const customerCodeQuery = parseQueryParam(queryParams.customerCode) as string;
    const incrementQuery = parseQueryParam(queryParams.increment) as string;

    const serviceIdQuery = parseQueryParam(queryParams.serviceId) as string;
    const serviceTypeCodeQuery = parseQueryParam(queryParams.serviceTypeCode) as ServiceTypesEnum;
    const itemIdQuery = parseQueryParam(queryParams.itemId) as string;

    const searchByQuery = parseQueryParam(queryParams.searchBy) as string;
    const searchValueQuery = parseQueryParam(queryParams.searchValue);
    const sortByQuery = parseQueryParam(queryParams.sortBy);
    const sortOrderQuery = parseQueryParam(queryParams.sortOrder) as TableSortOrder;
    const pageQuery = parseInt(parseQueryParam(queryParams.page) || '0');
    const rowsPerPageQuery = parseInt(parseQueryParam(queryParams.rowsPerPage) || DEFAULT_ROWS_PER_PAGE);
    const startDateQuery = parseQueryParam(queryParams.startDate);
    const endDateQuery = parseQueryParam(queryParams.endDate);

    const from = ((pageQuery) * rowsPerPageQuery);
    const size = rowsPerPageQuery;

    let query: ILogsQuery = { from, size };

    if (countryCodeQuery) query = { ...query, country_code: countryCodeQuery };
    if (customerCodeQuery) query = { ...query, customer_code: customerCodeQuery };
    if (incrementQuery) query = { ...query, increment: incrementQuery };

    if (serviceIdQuery) query = { ...query, service_id: serviceIdQuery };
    if (serviceTypeCodeQuery) query = { ...query, service_type_code: serviceTypeCodeQuery };
    if (itemIdQuery) query = { ...query, item_id: itemIdQuery };

    if (searchByQuery) query = { ...query, search_by: searchByQuery };
    if (searchValueQuery) query = { ...query, search_value: searchValueQuery };
    if (sortByQuery) query = { ...query, sort_by: sortByQuery?.replace('source.', '') };
    if (sortOrderQuery) query = { ...query, sort_order: sortOrderQuery };
    if (startDateQuery && endDateQuery) query = { ...query, start_date: startDateQuery, end_date: endDateQuery };

    if (downloadRequest) query = { ...query, download: true };

    return query;
  }

  public fetchAvailableLogServices = (envId: string, ctx?: ServerSidePropsContextType) => {
    return this.get<ILogServicesData[]>(
      `/environments/${envId}/logs/services`,
      undefined,
      undefined,
      ctx,
    );
  }

  public fetchAvailableLogServicesSsr = this.checkSsr<{ logServices: ILogServicesData[] }>(async (ctx, obj) => {
    try {
      const envId = parseQueryParam(ctx.query.envId);

      const logServices = await this.fetchAvailableLogServices(envId, ctx);

      return { ...obj, props: { ...obj?.props, logServices } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchServiceLogs = (envId: string, query: ILogsQuery, ctx?: ServerSidePropsContextType) => {
    return this.get<ILogs>(
      `/environments/${envId}/logs`,
      query as unknown as Record<string, unknown>,
      undefined,
      ctx,
    );
  }

  public fetchServiceLogsSsr = this.checkSsr<{ logs: ILogs }>(async (ctx, obj) => {
    try {
      const envId = parseQueryParam(ctx.query.envId);

      const query = this.getLogsQuery(ctx.query);

      const logs = await this.fetchServiceLogs(envId, query, ctx);

      return { ...obj, props: { ...obj?.props, logs } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEncryptionKeysSsr = this.checkSsr<{ encryptionKeys: EncryptionKeyResponse[] }>(async (ctx, obj) => {
    try {
      const envId = parseQueryParam(ctx.query.envId);
      const countryCode = parseQueryParam(ctx.query.country);
      const tenant = parseQueryParam(ctx.query.tenant);
      const increment = parseQueryParam(ctx.query.inc);

      const response = await this.get<EncryptionKeyResponse[]>(
        `/v2/environments/${envId}/secret_keys/${countryCode}/${increment}/${tenant}`,
        undefined,
        undefined,
        ctx,
      );
      const encryptionKeys = response?.map(v => ({ ...v, $TypeID: 'EncryptionKey' }));
      return { ...obj, props: { ...obj?.props, encryptionKeys } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchServiceTypesSsr = this.checkSsr<{ serviceTypes: ServiceTypeResponse[] }>(async (ctx, obj) => {
    try {
      const response = await this.get<{ data: ServiceTypeResponse[] }>('/services/types', undefined, undefined, ctx);
      const serviceTypes = response?.data?.map(v => ({ ...v, $TypeID: 'ServiceType' }));
      return { ...obj, props: { ...obj?.props, serviceTypes } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEmailGatewaysSsr = this.checkSsr<{ emailGateways: IEmailGatewayResponse[] }>(async (ctx, obj) => {
    try {
      if (!portalConfig.acl?.[Routes.emailGateway.create().pathname]) return { ...obj, props: { ...obj?.props, emailGateways: [] } };

      const envId = parseQueryParam(ctx.query.envId);
      if (!envId) {
        const envs = (obj?.props?.environments as EnvironmentResponseData[])?.filter(v => !v.removed_at)?.filter(v => v?.countries?.filter(v => v.activated)?.length > 0);
        const envsPromises = await Promise.all((envs || []).map(async env => {
          return await this.get<IEmailGatewayResponse[]>(`/environments/${env?.id}/egs`, undefined, undefined, ctx);
        }));

        return { ...obj, props: { ...obj?.props, emailGateways: envsPromises?.flatMap(v => v) } };
      }

      const emailGateways = await this.get<IEmailGatewayResponse[]>(`/environments/${envId}/egs`, undefined, undefined, ctx);
      return { ...obj, props: { ...obj?.props, emailGateways } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEmailGatewaySsr = this.checkSsr<{ emailGateway: IEmailGatewayResponse }>(async (ctx, obj) => {
    try {
      const envId = parseQueryParam(ctx.query.envId);
      const id = parseQueryParam(ctx.query.serviceId);
      const emailGateway = await this.get<IEmailGatewayResponse>(`/environments/${envId}/egs/${id}`, undefined, undefined, ctx);
      return { ...obj, props: { ...obj?.props, emailGateway } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchCountriesSsr = this.checkSsr<{ countries: InfraJsonCountry[] }>(async (ctx, obj) => {
    try {
      const countries = await this.get<InfraJsonCountry[]>('/infra', { filter: ctx.query.filter }, undefined, ctx);

      return { ...obj, props: { ...obj?.props, countries } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });


  public fetchNonTenancyCountriesSsr = this.checkSsr<{ countries: CountryResponseInterface[] }>(async (ctx, obj) => {
    try {
      const data = await this.get<CountriesResponseInterface>('/countries', undefined, undefined, ctx);

      return { ...obj, props: { ...obj?.props, countries: data?.countries } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });

  public fetchEnvironmentSchemaSsr = this.checkSsr<{ schema: ISchema[] }>(async (ctx, obj) => {
    if (!PortalConfig.acl?.[Routes.environment.aliases.list().pathname]) return { ...obj, props: { ...obj?.props, schema: [] } };
    try {
      const envId = parseQueryParam(ctx.query.envId);
      const response = await this.get<ISchemaResponse>(
        `/environments/${envId}/aliases`,
        undefined,
        undefined,
        ctx,
      );

      return { ...obj, props: { ...obj?.props, schema: convertResponseAliasesForForm(response) } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });


  public fetchAccessPoliciesSsr = this.checkSsr<{ policies: IPolicyResponse[] }>(async (ctx, obj) => {
    if (!PortalConfig.acl?.[Routes.environment.policies.list().pathname]) return { ...obj, props: { ...obj?.props, policies: [] } };
    try {
      const envId = parseQueryParam(ctx.query.envId);
      const response = await this.get<{ policies: IPolicyResponse[] }>(
        `/environments/${envId}/policies`,
        undefined,
        undefined,
        ctx,
      );

      return { ...obj, props: { ...obj?.props, policies: response?.policies || [] } };
    } catch (err) {
      return this.ssrErrorHandler(err);
    }
  });
}

const apiClient = new ApiClient();

export default apiClient;
