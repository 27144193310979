import { EventCorrId } from 'app/api/models/Event';
import { NextPageContext } from 'next';

export const getCorId = (ctx: NextPageContext) => {
  const rawHeaders = ctx?.res?.socket?.parser?.incoming?.rawHeaders as string[];
  const corIdIdx = rawHeaders?.findIndex(el => el === EventCorrId.internal) + 1;
  let corId = 'cant find ' + EventCorrId.internal;
  if (typeof corIdIdx === 'number' && corIdIdx !== -1) {
    corId = rawHeaders?.[corIdIdx];
  }

  return corId;
}
