import {
  GetServerSidePropsContext,
  GetServerSidePropsResult as NativeGetServerSidePropsResult,
  PreviewData,
  Redirect,
} from 'next';
import { ParsedUrlQuery } from 'querystring';

import { CurrentUser } from 'app/api/models/CurrentUser';
import { EventSeverity } from 'app/api/models/Event';

export type RouterQueryItem = string | string[] | undefined;

export interface NextGetConfig {
  publicRuntimeConfig: {
    [key: string]: string;
    APP_LOG_LEVEL: EventSeverity;
  };
}

export type ServerSidePropsContextType = GetServerSidePropsContext<
  { [key: string]: string | string[] | undefined },
  PreviewData
>;

export type ExtendedGetServerSideProps<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  P extends { [key: string]: any } = { [key: string]: any },
  Q extends ParsedUrlQuery = ParsedUrlQuery,
  D extends PreviewData = PreviewData,
> = (context: GetServerSidePropsContext<Q, D>, user: CurrentUser) => Promise<NativeGetServerSidePropsResult<P>>;

export type GetServerSidePropsResult<P> = { props: P } | { redirect: Redirect } | { notFound: true };

export const isPropsResultSsr = <T = object>(o: unknown): o is { props: T } => {
  const oo = o as { props: T };
  return typeof oo?.props === 'object' && typeof oo?.props !== 'function';
};

export const isRedirectResultSsr = (o: unknown): o is { redirect: Redirect } => {
  const oo = o as { redirect: Redirect };
  return typeof oo?.redirect === 'object' && typeof oo?.redirect?.destination === 'string';
};

export const isNotFoundResultSsr = (o: unknown): o is { notFound: boolean } => {
  const oo = o as { notFound: boolean };
  return typeof oo?.notFound === 'boolean';
};
