// 1 level
export interface BorderConfigV2 {
  id: string;
  name: string;
  version: number;
  created_at: string;
  immutable: boolean;
  border_target_endpoint: string;
  synchronization_endpoint: string;
  data: BorderConfigData;
  geoip: boolean;
  developer_mode: boolean;
}

// 2 level
export interface BorderConfigData {
  target: string;
  cors: BorderConfigCors;
  globalEntityId: boolean;
  country: string;
  features?: string[];
  supported_countries?: string[];
  dtk?: string;
  environmentId: string;
  redactions: BorderRedactionRuleInterface[] | BorderRedactionRuleExtendedInterface[];
  unredactions: BorderUnredactionRuleInterface[];
}

// 3 level
export interface BorderRedactionRuleInterface {
  path: string;
  method: string;
  entityIdPath: string;
  collectionName: string;
  globalEntityId?: boolean;
  entityErrorCorrectionFieldPath: string;
  strategies: BorderRedactionStrategyInterface[];
  operationMode?: BorderRuleOperationMode;
  plugin: BorderPlugins;
  keyAliasModelExternalId?: string;

  dataLossPrevention: {
    excludePaths: string[];
  };

  resJsonObj?: { [key: string]: string };
  reqJsonObj?: { [key: string]: string };

  recordsPath: string; // path to "records" field, may be pre-filled with "records"
  allOrNoneFlagPath: string; // path to "allOrNone" field, may be pre-filled with "allOrNone"
  externalIdPath?: string; // JSON path to external id (in body), should be optional
  updateByExternalIdFlag?: boolean; // whether to update by externalId, default "false". If externalIdPath is not specified, do not show this field
  searchPath?: string; // URL for remote error correction. Do not show if Processing mode

  userBackendConfiguration: {
    authEndpoint: string;
    authType: BorderSearchAuthType;
    idsFilterEndpoint: string;
    searchEndpoint: string;
    queryOffsetParam: string;
    queryLimitParam: string;
    defaultSearchLimit: number;
    endpointType: string;
  };
  criteriaMapping: {
    in: string;
    map: { key: string; value: string }[];
  }
}

export interface BorderRedactionRuleExtendedInterface {
  path: string;
  method: string;
  globalEntityId: boolean;
  plugin: BorderPlugins.extended;
  collections: BorderRedactionRuleExtendedCollectionInterface[];
  resJsonObj?: { [key: string]: string };
  reqJsonObj?: { [key: string]: string };
}

export interface BorderRedactionRuleExtendedCollectionInterface {
  collectionName: string;
  collectionPath: string;
  collectionResponsePath: string;
  entityIdPath: string;
  entityIdResponsePath: string;
  entityErrorCorrectionFieldPath: string;
  entityErrorCorrectionFieldResponsePath: string;
  strategies: BorderRedactionStrategyInterface[];
  operationMode?: BorderRuleOperationMode;
  keyAliasModelExternalId: string;
}

export interface BorderUnredactionRuleInterface {
  path: string;
  method: string;
  collections: BorderUnredactionRuleCollectionInterface[];
  resJsonObj?: { [key: string]: string };
  reqJsonObj?: { [key: string]: string };
}

// 4 level
export interface BorderRedactionStrategyInterface {
  path: string;
  strategy: StrategyTypes;
  in?: 'query' | 'body';
  strategyOptions?: {
    value?: string;
    length?: number;
    storeField?: boolean;
    type: UnredactionMaskingType;
    maskingLength?: number;
    maskingChar?: string;
    initialsLength?: number;
    delimiter?: UnredactionStrategyMaskingDelimeter;
  };
  searchableKey?: string;
}

export enum BorderRuleOperationMode {
  processing = 'processing',
  processingAndStorage = 'processingAndStorage',
}

export interface BorderUnredactionRuleCollectionInterface {
  name: string;
  entityIdPath: string;
  globalEntityId?: boolean;
  entityErrorCorrectionFieldPath: string;
  isErrorCorrectionFieldIdx?: string;
  strategies: BorderUnredactionStrategyInterface[];
  keyAliasModelExternalId: string;
}

// 5 level
export interface BorderUnredactionStrategyInterface {
  path: string;
  originalPath?: string;
  isErrorCorrectionField?: boolean;
  strategy?: UnredactionStrategyTypes;
  strategyOptions?: {
    type: UnredactionMaskingType;
    value?: string;
    maskingLength?: number;
    maskingChar?: string;
    initialsLength?: number;
    delimiter?: UnredactionStrategyMaskingDelimeter;
  };
}

// transform
export type BorderConfigPayload = Pick<BorderConfigV2, 'name'> & Omit<BorderConfigData, 'country' | 'environmentId'>;

export type BorderRedactionRuleResponse = Omit<BorderRedactionRuleInterface, 'searchable' | 'criteriaMapping'> & {
  searchable: { [key: string]: string };
  criteriaMapping: {
    in: string;
    map: { [key: string]: string };
  }
};

export type BorderConfigDataResponse = Omit<BorderConfigData, 'redactions'> & {
  redactions: BorderRedactionRuleInterface[] | BorderRedactionRuleExtendedInterface[];
};

export type BorderConfigDataInputForBackend = Pick<BorderConfigV2, 'name'> &
  Omit<BorderConfigData, 'redactions' | 'country' | 'environmentId'> & { redactions: BorderRedactionRuleResponse[] };

export type BorderConfigResponse = Omit<BorderConfigV2, 'data'> & { data: BorderConfigDataResponse };

export interface BorderConfigCors {
  'Access-Control-Allow-Origin': string;
}

export enum StrategyTypes {
  alphaNumeric = 'alphaNumeric',
  alphaNumericLowerCase = 'alphaNumericLowerCase',
  alphaNumericPersistent = 'alphaNumericPersistent',
  alphaPrepended = 'alphaPrepended',
  dateISO = 'dateISO',
  defaultDateISO = 'defaultDateISO',
  email = 'email',
  emailPersistent = 'emailPersistent',
  fixed = 'fixed',
  numeric = 'numeric',
  one = 'one',
  plain = 'plain',
  zero = 'zero',
  masking = 'masking',
}

export enum StrategyTypesPersistent {
  alphaNumericPersistent = 'alphaNumericPersistent',
  emailPersistent = 'emailPersistent',
  fixed = 'fixed',
  one = 'one',
  zero = 'zero',
  masking = 'masking',
}

export enum BorderPlugins {
  default = 'default',
  extended = 'bulk-create-update',
  borderSearch = 'border-search',
  salesforce = 'sf-multiple-upsert'
}

export enum BorderSearchAuthType {
  request = 'request'
}

export enum BorderSearchCriteriaMappingIn {
  body = 'body'
}

export enum BorderSearchEndpointType {
  search = 'search',
  records = 'records'
}

export enum UnredactionStrategyTypes {
  default = 'default',
  fixed = 'fixed',
  masking = 'masking',
}

export enum UnredactionStrategyMaskingDelimeter {
  space = ' ',
  underscore = '_',
  slash = '/',
  hyphen = '-'
}

export enum BorderConfigSections {
  properties = 'properties',
  redactions = 'redactions',
  unredactions = 'unredactions',
}

export enum UnredactionMaskingType {
  alphanumeric = 'alphanumeric',
  email = 'email'
}
