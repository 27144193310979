import { ServerSidePropsContextType } from 'app/types/nextjs';
import { HttpMethod } from 'app/utils/constants/http';
import type { ParsedUrlQuery } from 'querystring';

export enum EventCorrId {
  internal = 'x-inc-corr-id-int',
  external = 'x-inc-corr-id-ext'
}

export enum EventType {
  serversideRequest = 'application.portalfrontend.serverside.request',
  clientsideRequest = 'application.portalfrontend.clientside.request',
}

export enum EventSeverity {
  info = 'INFO',
  debug = 'DEBUG',
}

export enum EventStatus {
  start = 'START',
  success = 'SUCCESS',
  failure = 'FAILURE',
  retry = 'RETRY',
}

export const logServerSideEvent = (event: Event, ctx?: ServerSidePropsContextType) => {
  if (!ctx) return;
  if (event.severity === EventSeverity.info && event.status === EventStatus.success) return;
  if (event.severity === EventSeverity.info && event.status === EventStatus.failure) return;
  logEvent({
    ...event,
    type: EventType.serversideRequest,
    [EventCorrId.internal]: ctx?.req?.headers?.[EventCorrId.internal],
    [EventCorrId.external]: ctx?.req?.headers?.[EventCorrId.external],
    query: ctx?.query,
    module: ctx?.req?.url,
  })
}

export const logEvent = (event: Event) => {
  let eventString;
  if (event instanceof Event) {
    eventString = event.toString()
  } else {
    eventString = JSON.stringify(new Event(event));
  }

  console.log(eventString)
}

class Event {
  public [EventCorrId.internal]: string;
  public [EventCorrId.external]: string;

  public severity: EventSeverity;
  public type: string;

  public method?: HttpMethod;
  public url?: string;
  public time?: string;

  public message?: string;
  public status?: EventStatus;

  // query
  public query?: ParsedUrlQuery;
  public envId?: string | string[];
  public orgId?: string | string[];
  public country?: string | string[];
  public tenancy?: string | string[];
  public increment?: string | string[];

  public module?: string;

  public payload?: unknown;

  constructor(data: Event) {
    this[EventCorrId.internal] = data?.[EventCorrId.internal];
    this[EventCorrId.external] = data?.[EventCorrId.external];

    this.severity = data?.severity;
    this.type = data?.type;

    this.method = data?.method;
    this.url = data?.url;
    this.time = data?.time || new Date().toISOString();

    this.message = data?.message;
    this.status = data?.status;

    this.envId = data?.query?.envId;
    this.orgId = data?.query?.orgId;
    this.country = data?.query?.country;
    this.tenancy = data?.query?.tenant;
    this.increment = data?.query?.inc;

    this.module = data?.module;

    this.payload = data?.payload;
  }
}

export default Event;
