import type { ParsedUrlQuery } from 'querystring';

import { CountryCode } from 'app/types/country';
import { DashboardDataTypes, DashboardViewTypes } from 'app/types/dashboard';
import { CountryTenant } from 'app/types/infraJson';
import { LogsSearchType } from 'app/types/logs';
import { TableSortOrder } from 'app/types/table';
import { IDPEndpoint } from 'app/utils/environmentVariables';
import { ServiceTypesEnum } from 'app/types/services';

export class Route {
  public pathname: string;
  public query: ParsedUrlQuery;
  constructor(pathname: string, query: ParsedUrlQuery = {}) {
    this.pathname = pathname;
    this.query = Object.entries(query || {})
      ?.filter(([, val]) => val !== undefined)
      ?.reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});
  }
  toString = () => {
    const allQueriesArr = Object.entries(this.query || {});
    const paramsArr = allQueriesArr?.filter(([key]) => this.pathname.includes(key));
    const queriesArr = allQueriesArr?.filter(([key]) => !this.pathname.includes(key));
    const withReplacedParams = paramsArr.reduce(
      (acc, [key, val]) => acc.replace(`[${key}]`, val as string),
      this.pathname,
    );
    const queryStrArr = queriesArr?.map(([key, val]) => `${key}=${val as string}`);

    return withReplacedParams + (queryStrArr.length > 0 ? `?${queryStrArr.join('&')}` : '');
  };
}

class RoutesClass {
  RESIDENT_FUNCTIONS_ROOT = '/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/resident-functions';
  BORDER_ROOT = '/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/border';
  ENC_KEYS_ROOT = '/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/encryption';
  SERVICES_ROOT = '/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services';
  SDKS_ROOT = '/environments/[envId]/sdks';

  environment = {
    list: () => new Route('/environments'),
    get: (envId?: string) => new Route('/environments/[envId]', { envId }),
    create: (from?: string, type?: string) => new Route('/environments/create', { from, type }),
    update: (envId?: string) => new Route('/environments/[envId]/update', { envId }),
    delete: (envId?: string) => new Route('/environments/[envId]/delete', { envId }),
    activateCountry: (envId?: string, from?: string) => new Route('/environments/[envId]/activate-country', { envId, from }),
    aliases: {
      list: (envId?: string) => new Route('/environments/[envId]/schema', { envId }),
      create: (envId?: string) => new Route('/environments/[envId]/schema/create', { envId }),
      delete: (envId?: string) => new Route('/environments/[envId]/schema/delete', { envId }),
    },
    policies: {
      list: (envId?: string) => new Route('/environments/[envId]/policies', { envId }),
      create: (envId?: string) => new Route('/environments/[envId]/policies/create', { envId }),
      get: (envId?: string, policyId?: string) => new Route('/environments/[envId]/policies/[policyId]', { envId, policyId }),
      update: (envId?: string, policyId?: string) => new Route('/environments/[envId]/policies/[policyId]/update', { envId, policyId }),
      delete: (envId?: string, policyId?: string) => new Route('/environments/[envId]/policies/[policyId]/delete', { envId, policyId }),
    },
    logs: (envId?: string, startDate?: string, endDate?: string, countryCode?: CountryCode, customerCode?: CountryTenant, increment?: string, serviceId?: string, serviceTypeCode?: ServiceTypesEnum, itemId?: string, searchBy: LogsSearchType = LogsSearchType.requestID, sortBy: string = 'source.@timestamp', sortOrder: TableSortOrder.asc = TableSortOrder.asc, page: string = '0', rowsPerPage: string = '20', searchValue?: string) =>
      new Route('/environments/[envId]/logs', { envId, countryCode, customerCode, increment, serviceId, serviceTypeCode, itemId, searchBy, sortBy, sortOrder, searchValue, page, rowsPerPage, startDate, endDate }),
    restore: (envId?: string) => new Route('/environments/[envId]/restore', { envId }),
    manageConfigs: (envId?: string) => new Route('/environments/[envId]/manage-configuration', { envId }),
  };
  sdk = {
    list: (envId?: string) => new Route(this.SDKS_ROOT, { envId }),
    create: (envId?: string, from?: string) => new Route(this.SDKS_ROOT + '/create', { envId, from }),
    update: (envId?: string, sdkId?: string) => new Route(this.SDKS_ROOT + '/[sdkId]/update', { envId, sdkId }),
    delete: (envId?: string, sdkId?: string) => new Route(this.SDKS_ROOT + '/[sdkId]/delete', { envId, sdkId }),
  };
  service = {
    list: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route(this.SERVICES_ROOT, { envId, country, tenant, inc }),
    create: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, from?: string, type?: string) =>
      new Route(this.SERVICES_ROOT + '/create', { envId, country, tenant, inc, from, type }),
    renew: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, serviceId?: string) =>
      new Route(this.SERVICES_ROOT + '/[serviceId]/renew', { envId, country, tenant, inc, serviceId }),
    delete: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, serviceId?: string) =>
      new Route(this.SERVICES_ROOT + '/[serviceId]/delete', { envId, country, tenant, inc, serviceId }),
    update: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, serviceId?: string) =>
      new Route(this.SERVICES_ROOT + '/[serviceId]/edit', { envId, country, tenant, inc, serviceId }),
  };
  encryptionKey = {
    list: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) =>
      new Route(this.ENC_KEYS_ROOT, { envId, country, tenant, inc, filter: 'encryption-keys' }),
    create: (envId?: string, country?: string, tenant?: CountryTenant, inc?: string, type?: string) =>
      new Route(this.ENC_KEYS_ROOT + '/create', { envId, country, tenant, inc, type }),
    rotate: (envId?: string, country?: string, tenant?: CountryTenant, inc?: string) =>
      new Route(this.ENC_KEYS_ROOT + '/rotate', { envId, country, tenant, inc }),
    update: (envId?: string, country?: string, keyId?: string, tenant?: CountryTenant, inc?: string) =>
      new Route(this.ENC_KEYS_ROOT + '/[keyId]/update', { envId, country, tenant, inc, keyId }),
  };
  firewall = {
    list: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, page?: string, rowsPerPage?: string) =>
      new Route(`/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/firewall`, { envId, country, tenant, inc, page, rowsPerPage }),
  };
  member = {
    list: () => new Route('/members'),
    invite: () => new Route('/members/invite'),
    transfer: () => new Route('/members/transfer-ownership'),
    changeRole: (id?: string) => new Route('/members/[id]/change-role', { id }),
    remove: (id?: string) => new Route('/members/[id]/remove', { id }),
    resetPassword: (id?: string) => new Route('/members/[id]/reset-password', { id }),
  };
  invite = {
    list: (id?: string) => new Route('/invites', { id }),
    cancel: (id?: string) => new Route('/invites/[id]/cancel', { id }),
    resend: (id?: string) => new Route('/invites/[id]/resend', { id }),
  };
  residentFunction = {
    create: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, endpoint?: boolean) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/resident-functions/create', { envId, country, tenant, inc, endpoint }),
    list: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, page: string = '0', rowsPerPage: string = '10') => new Route(this.RESIDENT_FUNCTIONS_ROOT, { envId, serviceId, country, tenant, inc, page, rowsPerPage }),
    publish: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) =>
      new Route(this.RESIDENT_FUNCTIONS_ROOT + '/publish', { envId, country, tenant, inc, serviceId }),
    configure: (envId?: string, serviceId?: string, functionName?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) =>
      new Route(this.RESIDENT_FUNCTIONS_ROOT + '/[functionName]/configure', { envId, serviceId, functionName: encodeURIComponent(functionName), country, tenant, inc }),
    delete: (envId?: string, serviceId?: string, functionName?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) =>
      new Route(this.RESIDENT_FUNCTIONS_ROOT + '/[functionName]/delete', { envId, serviceId, functionName: encodeURIComponent(functionName), country, tenant, inc }),
  };
  dashboard = {
    list: () => new Route('/dashboard'),
    get: (
      orgId?: string,
      envId?: string,
      startDate?: string,
      endDate?: string,
      dataType?: DashboardDataTypes,
      viewType?: DashboardViewTypes,
    ) => new Route('/dashboard/[envId]', { envId, orgId, startDate, endDate, dataType, viewType }),
  };
  emailGateway = {
    create: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/email-gateways/create', { envId, country, tenant, inc }),
    update: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/email-gateways/edit', { envId, serviceId, country, tenant, inc }),
    embed: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/email-gateways/embed', { envId, serviceId, country, tenant, inc }),
    delete: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/email-gateways/delete', { envId, serviceId, country, tenant, inc }),
  };
  paymentVault = {
    create: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, type?: string) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/payment-vault/create', { envId, country, tenant, inc, type }),
    embed: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/payment-vault/embed', { envId, serviceId, country, tenant, inc }),
    delete: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/payment-vault/delete', { envId, serviceId, country, tenant, inc }),
  };
  restApi = {
    manageCredentials: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, serviceId?: string) =>
      new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/restapi/manage-credentials', { envId, country, tenant, inc, serviceId }),
    manageCredentialsCreate: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, serviceId?: string) =>
      new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/restapi/manage-credentials/create', { envId, country, tenant, inc, serviceId }),
    manageCredentialsUpdate: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, serviceId?: string, credentialsId?: string) =>
      new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/restapi/manage-credentials/[credentialsId]/update', { envId, country, tenant, inc, serviceId, credentialsId }),
    manageCredentialsDelete: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string, serviceId?: string, credentialsId?: string) =>
      new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/restapi/manage-credentials/[credentialsId]/delete', { envId, country, tenant, inc, serviceId, credentialsId }),
  };
  border = {
    list: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route(this.BORDER_ROOT, { envId, serviceId, country, tenant, inc }),
    create: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route(this.BORDER_ROOT + '/create', { envId, serviceId, country, tenant, inc }),
    import: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) =>
      new Route(this.BORDER_ROOT + '/import', { envId, serviceId, country, tenant, inc }),
    view: (envId?: string, serviceId?: string, configurationId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) =>
      new Route(this.BORDER_ROOT + '/[configurationId]/view', { envId, serviceId, configurationId, country, tenant, inc }),
    update: (envId?: string, serviceId?: string, configurationId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) =>
      new Route(this.BORDER_ROOT + '/[configurationId]/edit', { envId, serviceId, configurationId, country, tenant, inc }),
    delete: (envId?: string, serviceId?: string, configurationId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) =>
      new Route(this.BORDER_ROOT + '/[configurationId]/delete', { envId, serviceId, configurationId, country, tenant, inc }),
  };
  salesforce = {
    certificate: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) =>
      new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/salesforce/certificate', { envId, country, tenant, inc, serviceId }),
  };
  ai = {
    create: (envId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/ai/create', { envId, country, tenant, inc }),
    configure: (envId?: string, serviceId?: string, country?: CountryCode, tenant?: CountryTenant, inc?: string) => new Route('/environments/[envId]/country/[country]/tenant/[tenant]/inc/[inc]/services/[serviceId]/ai/configure', { envId, serviceId, country, tenant, inc }),
  };
  profile = {
    list: () => new Route('/profile'),
    mfaApp: () => new Route('/profile/mfa-totp'),
    mfaEmail: () => new Route('/profile/mfa-email'),
    changeEmail: () => new Route('/profile/change-email'),
  };
  company = {
    list: () => new Route('/company'),
    create: () => new Route('/company/create'),
    update: () => new Route('/company/update'),
    deleted: () => new Route('/company/deleted'),
  };
  email = {
    confirm: () => new Route('/confirm-email/[token]'),
    old: () => new Route('/email/confirm/old'),
    new: () => new Route('/email/confirm/new'),
  };
  auth = {
    login: () => new Route('/login'),
    loginSso: () => new Route('/login/sso'),
    logout: () => new Route('/logout'),
  };
  htmlGateway = {
    list: () => new Route('/html-gateway'),
  };
  webFormGateway = {
    list: () => new Route('/web-form-gateway'),
  };
  error = {
    notFound: () => new Route('/404'),
    internalError: () => new Route('/500')
  }
}

export const Routes = new RoutesClass();

export const EXTERNAL_LINKS = {
  DOCS: {
    LANDING: 'https://docs.incountry.com',
    PORTAL: 'https://docs.incountry.com/portal/getting-started-portal/',
    DOCS: 'https://docs.incountry.com/portal/getting-started-portal/',
    SALESFORCE: {
      GENERAL: 'https://docs.incountry.com/salesforce/about/',
      MANAGING_ENDPOINTS: 'https://docs.incountry.com/salesforce/managing-endpoints/#creating-a-new-endpoint'
    },
    REST_API: {
      general: 'https://docs.incountry.com/rest-api/',
      crud: 'https://docs.incountry.com/data-residency-as-a-service/rest-api-crud-requests/'
    },
    RESIDENT_FUNCTIONS: 'https://docs.incountry.com/data-residency-as-a-service/resident-functions-portal/',
    PAYMENT_GATEWAYS:
      'https://docs.incountry.com/payment-gateway/#integrating-the-payment-elements-library-into-the-payment-workflow',
    BORDER: 'https://docs.incountry.com/data-residency-as-a-service/web-services-proxy-portal/',
    BORDER_CONFIG_REDACTION:
      'https://docs.incountry.com/data-residency-as-a-service/web-services-proxy-portal/#managing-redaction-rules',
    BORDER_CONFIG_UNREDACTION:
      'https://docs.incountry.com/data-residency-as-a-service/web-services-proxy-portal/#managing-unredaction-rules',
    SCHEMA: 'https://docs.incountry.com/data-residency-as-a-service/data-schema/',
  },
  IDP: {
    RESET_REQUEST: `${IDPEndpoint}/auth/password/recovery`,
    REGISTER: `${IDPEndpoint} /auth/register`,
    RESET_EMAIL_REQUEST: `${IDPEndpoint} /auth/resend_user_confirm`,
  },
};

export const EXCLUDED_FROM_LAYOUT = {
  [Routes.company.create().pathname]: true,
  [Routes.company.deleted().pathname]: true,
  [Routes.email.old().pathname]: true,
  [Routes.email.new().pathname]: true,
};

export const RF_ROUTES = [
  Routes.residentFunction.create().pathname,
  Routes.residentFunction.list().pathname,
  Routes.residentFunction.publish().pathname,
  Routes.residentFunction.configure().pathname,
  Routes.residentFunction.delete().pathname,
];
