import { ISdk } from 'app/types/environments';
import { CountryTenant } from './infraJson';
import { DefaultTFuncReturn } from 'i18next';

export interface IServiceCard {
  id: string;
  typeCode: ServiceTypesEnum;
  disabled?: boolean;
  disabledTitle?: string | DefaultTFuncReturn;
  title: string | DefaultTFuncReturn;
  desc: string | DefaultTFuncReturn;
  svgSrc: string;
  svgAlt: string;
}

export interface ServiceTypeResponse {
  id: string;
  name: string;
  code: ServiceTypesEnum;
}

export interface ServiceResponse {
  id: string;
  name: string;
  type: ServiceTypeResponse;
  country_code: string;
  created_at: string;
  sdk?: ISdk;
  additional_sdks?: ISdk[];
  increment: string;
  customer_code: CountryTenant;
  has_cert?: boolean;
  oss_enabled?: boolean;
  entry_url?: string;
  aclEndpoint?: string;
  serviceSdk?: ISdk;
  policy_external_id?: string;
  auth_url?: string;
  ai_config?: AiServiceConfig;
}

export interface CreateBorderServiceResponse {
  data: {
    env_integration: {
      id: string;
    };
    proxy_url: string;
    integration: {
      name: string;
      uuid: string;
    };
  };
}

export enum ServiceTypeFilter {
  selfservice = 'selfservice',
  residentFunctions = 'resident-functions',
  border = 'border',
  encryptionKeys = 'encryption-keys',
  stripe = 'stripe',
  braintree = 'braintree',
  emailGateway = 'email-gateway',
}

export enum ServiceTypesEnum {
  salesforce = 'sf',
  residentFunction = 'rf',
  restapiSse = 'rs',
  border = 'bo',
  encryptionKeys = 'ec',
  paymentVault = 'pv',
  emailGateway = 'eg',
  ai = 'ai',
}

export const serviceCodeToFilterMap = {
  [ServiceTypesEnum.salesforce]: ServiceTypeFilter.selfservice,
  [ServiceTypesEnum.restapiSse]: ServiceTypeFilter.selfservice,
  [ServiceTypesEnum.residentFunction]: ServiceTypeFilter.residentFunctions,
  [ServiceTypesEnum.border]: ServiceTypeFilter.border,
  [ServiceTypesEnum.encryptionKeys]: ServiceTypeFilter.encryptionKeys,
  [ServiceTypesEnum.paymentVault]: ServiceTypeFilter.stripe,
  [ServiceTypesEnum.emailGateway]: ServiceTypeFilter.emailGateway,
};

export const serviceCodeToServiceNameMap = {
  [ServiceTypesEnum.restapiSse]: 'restapi',
  [ServiceTypesEnum.salesforce]: 'salesforce',
  [ServiceTypesEnum.residentFunction]: 'residentfunctions',
  [ServiceTypesEnum.border]: 'border',
};

export enum AiServiceModels {
  claudeInstant = 'anthropic.claude-instant-v1',
  claude = 'anthropic.claude-v2',
  claudeSonnet = 'anthropic.claude-3-sonnet-20240229-v1\:0',
  claudeHaiku = 'anthropic.claude-3-haiku-20240307-v1:0',
  claude35Sonnet = 'anthropic.claude-3-5-sonnet-20240620-v1:0',
  llama38b = 'meta.llama3-8b-instruct-v1:0',
  llama370b = 'meta.llama3-70b-instruct-v1:0',
}

export enum AiServiceLanguage {
  original = 'original',
  english = 'english',
}

export enum AiServiceMaskingAge {
  keep = 'keep',
  categories = 'categories',
  ranges = 'ranges',
  placeholder = 'placeholder',
}

export enum AiServiceMaskingLocation {
  keep = 'keep',
  region = 'region',
  country = 'country',
  city = 'city',
  placeholder = 'placeholder',
}

export enum AiServiceMaskingMedical {
  keep = 'keep',
  group = 'group',
  mask = 'mask',
  placeholder = 'placeholder',
}

export enum AiServiceMaskingFinancial {
  keep = 'keep',
  mask = 'mask',
  placeholder = 'placeholder',
}

export enum AiServiceMaskingWeight {
  keep = 'keep',
  ranges = 'ranges',
  placeholder = 'placeholder',
}

export enum AiServiceMaskingWeightUnit {
  keep = 'keep',
  pounds = 'pounds',
  kilograms = 'kilograms',
}

export enum AiServiceMaskingName {
  keep = 'keep',
  placeholder = 'placeholder',
}

export enum AiServiceMaskingGender {
  keep = 'keep',
  mask = 'mask',
}

export interface AiServiceKnowledgeBase {
  [key: string]: { [key: string]: string | number }[]
}

export interface AiServiceConfigAnonymizationOptions {
  age?: AiServiceMaskingAge;
  age_ranges?: [number, number][];
  name?: AiServiceMaskingName;
  gender?: AiServiceMaskingGender;
  location?: AiServiceMaskingLocation;
  medical_condition?: AiServiceMaskingMedical;
  financial?: AiServiceMaskingFinancial;
  weight?: AiServiceMaskingWeight;
  weight_ranges?: [number, number][];
  weight_unit?: AiServiceMaskingWeightUnit;
}

export interface AiServiceConfig {
  local_model_name?: AiServiceModels;
  local_model_region?: 'us-east-1';
  global_model_name?: AiServiceModels;
  global_model_region?: 'us-east-1';
  prompt_language?: AiServiceLanguage;
  knowledge_base?: AiServiceKnowledgeBase;
  anonymization_options?: AiServiceConfigAnonymizationOptions;
}
