import styled from '@emotion/styled';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import Panel from 'app/components/common/Panel';
import {
  BorderConfigDataInputForBackend,
  BorderConfigResponse,
  BorderConfigV2,
  BorderPlugins,
  BorderRedactionRuleExtendedInterface,
  BorderRedactionRuleInterface,
  BorderRedactionRuleResponse,
  BorderRedactionStrategyInterface,
  BorderRuleOperationMode,
  BorderSearchAuthType,
  BorderSearchCriteriaMappingIn,
  BorderSearchEndpointType,
  StrategyTypes,
  UnredactionMaskingType,
  UnredactionStrategyTypes,
} from 'app/types/border';
import { get, uniq } from 'lodash';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { HttpMethod } from '../constants/http';
import { DefaultTFuncReturn } from 'i18next';
import { FormMode, FormSelectOptionInterface } from 'app/types/forms';
import Link from 'next/link';
import { Routes } from 'app/utils/constants';
import AddIcon from '@mui/icons-material/Add';
import { ISchema } from 'app/types/environments';
import { getBorderJsonExample } from '../browserStorage';
import { colors } from 'app/theme-colors';

export const STRATEGY_TYPES_ARR = Object.values(StrategyTypes);
export const UNREDACTION_STRATEGY_TYPES_ARR = Object.values(UnredactionStrategyTypes);

export const DEFAULT_STRATEGY_TYPE = STRATEGY_TYPES_ARR[1];

export const NOT_SEARCHABLE_KEY = 'Not Searchable';
export const HIDE_ALIASES_ALERT = 'Showing only keys with assigned fields';
export const CREATE_ALIAS_MODEL = 'CREATE_ALIAS_MODEL'
export const USE_LEGACY_KEYS = 'InCountry schema'

export const SEARCHABLE_KEYS = Array.from({ length: 25 }, (_, i) => `key${i + 1}`);
export const RANGE_KEYS = Array.from({ length: 5 }, (_, i) => `range_key${i + 1}`);
export const DECIMAL_KEYS = Array.from({ length: 5 }, (_, i) => `decimal_key${i + 1}`);

export const STRATEGY_DEFAULT_LENGTH = 20;
export const STRATEGY_PERSISTENT_DEFAULT_LENGTH = 64;

export const ALIAS_RANGE_KEYS = Array.from({ length: 5 }, (_, i) => `range_key${i + 1}`);
export const ALIAS_DECIMAL_KEYS = Array.from({ length: 5 }, (_, i) => `decimal_key${i + 1}`);
export const ALIAS_SERVICE_KEYS = Array.from({ length: 5 }, (_, i) => `service_key${i + 1}`);

export const STRING_KEYS = [
  ...SEARCHABLE_KEYS,
]


export const DATE_KEYS = [
  'created_at',
  'updated_at',
  'expires_at',
]

export const SCOPE_KEYS_FOR_POLICY = [
  ...SEARCHABLE_KEYS,
  ...ALIAS_RANGE_KEYS,
  ...ALIAS_DECIMAL_KEYS,
];

interface IDeleteButtonProps {
  title?: string | DefaultTFuncReturn;
  size?: string;
  onClick: () => void;
}

export const DeleteButton: React.FunctionComponent<IDeleteButtonProps> = ({ onClick, title = 'Delete', size = 'medium' }) => (
  <div>
    <Tooltip title={title} placement='top'>
      <IconButton size={size} aria-label='Delete' onClick={onClick}>
        <ClearIcon fontSize={size} />
      </IconButton>
    </Tooltip>
  </div>
);

export const prependPath = (path: string) => {
  if (!path.startsWith('$.')) {
    return '$.' + path;
  }

  return path;
}

export const getJsonPaths = (obj: any, parent?: string): string[] => {
  const keys = Object.keys(obj || {});
  return keys?.flatMap(v => {
    if (typeof obj?.[v] === 'object') {
      return getJsonPaths(obj?.[v], parent ? `${parent}${!isNaN(parseInt(v)) ? '[*]' : '.' + v}` : `$.${v}`)
    }
    return parent ? `${parent}.${v}` : `$.${v}`
  })
}

export const getEntityPaths = (obj: any, parent?: string): string[] => {
  const keys = Object.keys(obj || {});
  return keys?.flatMap(v => {
    if (typeof obj?.[v] === 'object') {
      return getEntityPaths(obj?.[v], parent ? `${parent}${!isNaN(parseInt(v)) ? '[*]' : '.' + v}` : `$.${v}`)
    }
    return parent || '';
  })
}

export const getEntityFieldsPaths = (obj: any, path: string) => {
  if (!path) return [];
  if (path === '$') return getJsonPaths(obj)
  const p = path.slice(2).replaceAll('[*]', '.[0]');
  return uniq(getJsonPaths(get(obj, p), path))?.map(v => '$' + v.slice(path?.length));
}

export const isRedactionRulesFilled = (redactions: BorderRedactionRuleInterface[]) => {
  return (redactions?.length === 1 && redactions?.some(v => v.method === HttpMethod.Delete))
    || redactions?.some(v => v.plugin === BorderPlugins.borderSearch)
    || (redactions?.length > 0 && redactions?.[0]?.strategies?.length > 0 && redactions?.[0]?.strategies?.[0]?.path?.length > 0 && redactions?.[0]?.entityIdPath?.length > 0);
}

const createRedactionSearchRule = (targetEndpoint: string, r: BorderRedactionRuleInterface, schema: ISchema[]) => {
  const { plugin, operationMode, strategies, collectionName, entityIdPath, globalEntityId, entityErrorCorrectionFieldPath, reqJsonObj, resJsonObj, ...rule } = r;

  const data = {
    ...rule,
    plugin,
    userBackendConfiguration: {
      ...rule.userBackendConfiguration,
      endpointType: BorderSearchEndpointType.search,
      searchEndpoint: targetEndpoint + r.path,
    },
    criteriaMapping: {
      ...rule?.criteriaMapping,
      map: (rule?.criteriaMapping?.map || [])?.reduce((acc, cur) => {
        const keyFromAlias = (schema || []).find(v => v?.externalId === rule?.keyAliasModelExternalId)?.fields?.find(v => v?.alias === cur?.value)?.key;
        return ({ ...acc, [cur.key]: keyFromAlias || cur.value });
      }, {}) as { [key: string]: string }
    },
  };
  if (data?.keyAliasModelExternalId === USE_LEGACY_KEYS) {
    delete data?.keyAliasModelExternalId;
  }

  return data;
}

const transformStrategies = (strategies: BorderRedactionStrategyInterface[] = [], operationMode?: BorderRuleOperationMode, inParam?: 'body' | 'query') => {
  return strategies?.map(strategyItem => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { searchableKey, ...rest } = strategyItem;
    const length = rest.strategyOptions?.length as unknown as string;
    const value = rest.strategyOptions?.value;
    return {
      ...rest,
      path: inParam === 'query' ? prependPath(strategyItem?.path) : strategyItem?.path,
      ...(inParam ? { in: inParam } : {}),
      strategyOptions: {
        ...rest.strategyOptions,
        storeField: inParam === 'query' ? false : operationMode === BorderRuleOperationMode.processingAndStorage,
        ...(length !== undefined ? { length: parseInt(length, 10) } : {}),
        ...(value !== undefined ? { value: value?.toString() } : {}),
      },
    };
  });
};

const createRedactionRuleExtended = (r: BorderRedactionRuleExtendedInterface, globalEntityId: boolean, schema?: ISchema[]) => {
  const { reqJsonObj, resJsonObj, ...rule } = r;
  return {
    ...rule,
    globalEntityId,
    collections: rule?.collections?.map(collection => {
      const operationMode = collection?.operationMode;
      delete collection?.operationMode;
      if (collection?.keyAliasModelExternalId === USE_LEGACY_KEYS) {
        delete collection?.keyAliasModelExternalId;
      }
      return {
        ...collection,
        strategies: transformStrategies(collection?.strategies, operationMode),
        searchable: (collection.strategies || []).reduce((prev, curr) => {
          if (curr.searchableKey && curr.searchableKey !== NOT_SEARCHABLE_KEY) {
            const keyFromAlias = (schema || []).find(v => v?.externalId === collection?.keyAliasModelExternalId)?.fields?.find(v => v?.alias === curr?.searchableKey)?.key;
            return { ...prev, [keyFromAlias || curr.searchableKey]: curr.path };
          }
          return prev;
        }, {}),
      }
    })
  };
}

const prepareRedactionsRulesForBackend = (border: BorderConfigV2, randomCollectionName: string, schema?: ISchema[]) => {
  return border?.data?.redactions?.map(r => {
    if (r.plugin === BorderPlugins.borderSearch) {
      return createRedactionSearchRule(border?.data?.target, r as BorderRedactionRuleInterface, schema);
    }

    if (r.plugin === BorderPlugins.extended) {
      return createRedactionRuleExtended(r as BorderRedactionRuleExtendedInterface, border?.data?.globalEntityId, schema)
    }

    const { operationMode, plugin, criteriaMapping, userBackendConfiguration, reqJsonObj, resJsonObj, externalIdPath, searchPath, dataLossPrevention, ...rule } = r as BorderRedactionRuleInterface;

    if (rule?.keyAliasModelExternalId === USE_LEGACY_KEYS) {
      delete rule?.keyAliasModelExternalId;
    }

    if (rule.method === HttpMethod.Delete) {
      return {
        method: rule?.method,
        path: rule?.path,
        isDeleteRequest: true,
        collectionName: rule?.collectionName || randomCollectionName,
        keyAliasModelExternalId: rule?.keyAliasModelExternalId,
        globalEntityId: border?.data?.globalEntityId,
      }
    }
    const excludePaths = dataLossPrevention?.excludePaths?.map(v => v?.value);

    const queryStrategies = rule?.queryStrategies;
    delete rule?.queryStrategies;

    return {
      ...rule,
      collectionName: r?.collectionName || randomCollectionName,
      globalEntityId: border?.data?.globalEntityId,
      searchable: {
        ...(rule.strategies || []).reduce((prev, curr) => {
          if (curr.searchableKey && curr.searchableKey !== NOT_SEARCHABLE_KEY) {
            const keyFromAlias = (schema || []).find(v => v?.externalId === rule?.keyAliasModelExternalId)?.fields?.find(v => v?.alias === curr?.searchableKey)?.key;
            return { ...prev, [keyFromAlias || curr.searchableKey]: curr.path };
          }
          return prev;
        }, {}),
        ...(queryStrategies || []).reduce((prev, curr) => {
          if (curr.searchableKey && curr.searchableKey !== NOT_SEARCHABLE_KEY) {
            const keyFromAlias = (schema || []).find(v => v?.externalId === rule?.keyAliasModelExternalId)?.fields?.find(v => v?.alias === curr?.searchableKey)?.key;
            return { ...prev, [keyFromAlias || curr.searchableKey]: prependPath(curr.path) };
          }
          return prev;
        }, {})
      },
      ...(r.plugin === BorderPlugins.salesforce ? { plugin: r.plugin } : {}),
      ...((r.plugin === BorderPlugins.salesforce && externalIdPath) ? { externalIdPath } : {}),
      ...((r.plugin === BorderPlugins.salesforce && searchPath) ? { searchPath } : {}),
      ...(excludePaths?.length > 0 ? { dataLossPrevention: { excludePaths } } : {}),
      strategies: [...transformStrategies(rule?.strategies, operationMode), ...transformStrategies(queryStrategies, operationMode, 'query')],
    };
  })
}

export const convertBorderConfigForBackend = (
  border: BorderConfigV2,
  countryCode?: string,
  schema?: ISchema[],
): BorderConfigDataInputForBackend => {
  const randomCollectionName = uuidv4();
  const excludePaths = border?.data?.redactions?.flatMap(v => v?.dataLossPrevention?.excludePaths)?.filter(v => v);

  const features = [];
  if (border.geoip && countryCode) features.push('geoip');
  if (excludePaths?.length > 0) features.push('data_loss_prevention');
  if (border.developer_mode) features.push('developer_mode');

  return {
    name: border.name,
    target: border.data.target,
    ...(features?.length > 0 ? { features } : {}),
    ...(border.corsEnabled ? { cors: { "Access-Control-Allow-Origin": border?.data.cors['Access-Control-Allow-Origin'] } } : {}),
    ...(border.geoip && countryCode ? { supported_countries: [countryCode?.toUpperCase()] } : {}),
    ...(border.data.dtk ? { dtk: border.data.dtk } : {}),

    redactions: prepareRedactionsRulesForBackend(border, randomCollectionName, schema),
    unredactions: border.data.unredactions?.map(v => {
      delete v?.resJsonObj;
      delete v?.reqJsonObj;

      return ({
        ...v,
        collections: v.collections?.map(collection => {
          const entityErrorCorrectionFieldPath = collection.entityErrorCorrectionFieldPath;
          delete collection.isErrorCorrectionFieldIdx;
          if (collection?.keyAliasModelExternalId === USE_LEGACY_KEYS) {
            delete collection?.keyAliasModelExternalId;
          }
          return {
            ...collection,
            name: collection?.name || randomCollectionName,
            globalEntityId: border?.data?.globalEntityId,
            strategies: collection.strategies?.map(strategy => {

              if (strategy.path === entityErrorCorrectionFieldPath) {
                strategy.isErrorCorrectionField = true;
              }

              if (!strategy?.originalPath) {
                delete strategy?.originalPath;
              }

              if (strategy.strategy === UnredactionStrategyTypes.default) {
                delete strategy.strategy;
                delete strategy.strategyOptions;

                return strategy;
              }

              if (strategy.strategy === UnredactionStrategyTypes.fixed) {
                return { ...strategy, strategyOptions: { value: strategy?.strategyOptions?.value } };
              }

              if (strategy?.strategy === UnredactionStrategyTypes.masking) {

                if (strategy?.strategyOptions?.type === UnredactionMaskingType.email) {
                  return ({
                    ...strategy,
                    strategyOptions: {
                      type: strategy?.strategyOptions?.type,
                      initialsLength: parseInt(strategy?.strategyOptions?.initialsLength as unknown as string),
                      maskingChar: strategy?.strategyOptions?.maskingChar,
                      maskingLength: parseInt(strategy?.strategyOptions?.maskingLength as unknown as string),
                    }
                  })
                }

                if (strategy?.strategyOptions?.type === UnredactionMaskingType.alphanumeric) {
                  return ({
                    ...strategy,
                    strategyOptions: {
                      type: strategy?.strategyOptions?.type,
                      initialsLength: parseInt(strategy?.strategyOptions?.initialsLength as unknown as string),
                      maskingChar: strategy?.strategyOptions?.maskingChar,
                      maskingLength: parseInt(strategy?.strategyOptions?.maskingLength as unknown as string),
                      delimiter: strategy?.strategyOptions?.delimiter
                    }
                  })
                }
              }

              return strategy;
            }),
          };
        }),
      })
    }
    ),
  };
};

const transformStrategiesForForm = (borderResponse: BorderRedactionRuleResponse, strategies: BorderRedactionStrategyInterface[] = [], schema: ISchema[] = []) => {
  return strategies?.map(strategy => {
    const searchableKey = Object.entries(borderResponse.searchable || {})
      ?.filter(v => v[1] === strategy.path)
      ?.map(v => v[0])?.[0];

    const aliasFromKey = (schema || []).find(v => v?.externalId === borderResponse?.keyAliasModelExternalId)?.fields?.find(v => v?.key === searchableKey)?.alias;
    return {
      ...strategy,
      searchableKey: aliasFromKey || searchableKey || NOT_SEARCHABLE_KEY,
    };
  })
}

export const convertBorderConfigForForm = (borderResponse: BorderConfigResponse, schema?: ISchema[]): BorderConfigV2 => {
  return {
    ...borderResponse,
    ...(borderResponse?.data?.features?.includes('geoip') && borderResponse?.data?.supported_countries ? { geoip: true } : {}),
    ...(borderResponse?.data?.features?.includes('developer_mode') ? { developer_mode: true } : {}),
    corsEnabled: typeof borderResponse?.data?.cors?.['Access-Control-Allow-Origin'] === 'string',
    data: {
      ...borderResponse?.data,
      globalEntityId: borderResponse?.data?.redactions?.filter(r => (r?.plugin !== BorderPlugins.borderSearch && r.method !== HttpMethod.Delete))?.every(v => v.globalEntityId),
      redactions: (borderResponse?.data?.redactions || []).map(a => {
        const reqJson = getBorderJsonExample(borderResponse.data.environmentId, 'REQUEST', a.method, a.path);
        const resJson = getBorderJsonExample(borderResponse.data.environmentId, 'RESPONSE', a.method, a.path);
        const reqJsonObj = reqJson ? { reqJsonObj: JSON.parse(reqJson) } : {};
        const resJsonObj = resJson ? { resJsonObj: JSON.parse(resJson) } : {};
        if (a?.plugin === BorderPlugins.borderSearch) {
          const { strategies, operationMode, entityIdPath, collectionName, globalEntityId, entityErrorCorrectionFieldPath, ...rest } = a;
          return {
            ...rest,
            ...reqJsonObj,
            ...resJsonObj,
            userBackendConfiguration: {
              ...rest.userBackendConfiguration,
              endpointType: rest?.userBackendConfiguration?.searchEndpoint !== undefined ? BorderSearchEndpointType.search : BorderSearchEndpointType.records
            },
            criteriaMapping: {
              ...rest?.criteriaMapping,
              map: Object.entries(rest?.criteriaMapping?.map || {}).map(m => {
                const aliasFromKey = (schema || []).find(v => v?.externalId === a?.keyAliasModelExternalId)?.fields?.find(v => v?.key === m?.[1])?.alias;
                return ({ key: m?.[0], value: aliasFromKey || m?.[1] })
              })
            },
          }
        }

        const excludePaths = a?.dataLossPrevention?.excludePaths;

        if (Array.isArray(a?.collections)) {
          return {
            ...a,
            ...(excludePaths?.length > 0 ? { dataLossPrevention: { excludePaths: excludePaths?.map(v => ({ value: v })) } } : {}),
            plugin: a?.plugin || BorderPlugins.default,
            ...reqJsonObj,
            ...resJsonObj,
            collections: a?.collections?.map(c => {
              return ({
                ...c,
                operationMode: (c?.strategies || []).every(v => v.strategyOptions?.storeField)
                  ? BorderRuleOperationMode.processingAndStorage
                  : BorderRuleOperationMode.processing,
                strategies: (c?.strategies || []).map(strategy => {
                  const searchableKey = Object.entries(c.searchable || {})
                    ?.filter(v => v[1] === strategy.path)
                    ?.map(v => v[0])?.[0];

                  const aliasFromKey = (schema || []).find(v => v?.externalId === c?.keyAliasModelExternalId)?.fields?.find(v => v?.key === searchableKey)?.alias;
                  return {
                    ...strategy,
                    searchableKey: aliasFromKey || searchableKey || NOT_SEARCHABLE_KEY,
                  };
                }),
              })
            })
          }
        }

        return {
          ...a,
          ...(excludePaths?.length > 0 ? { dataLossPrevention: { excludePaths: excludePaths?.map(v => ({ value: v })) } } : {}),
          plugin: a?.plugin || BorderPlugins.default,
          keyAliasModelExternalId: a.keyAliasModelExternalId || USE_LEGACY_KEYS,
          ...reqJsonObj,
          ...resJsonObj,
          strategies: transformStrategiesForForm(a, a?.strategies?.filter(str => !str?.in), schema),
          queryStrategies: transformStrategiesForForm(a, a?.strategies?.filter(str => str?.in === 'query'), schema),
          operationMode: (a?.strategies || []).every(v => v.strategyOptions?.storeField)
            ? BorderRuleOperationMode.processingAndStorage
            : BorderRuleOperationMode.processing,
        }
      }),
      unredactions: (borderResponse?.data?.unredactions || []).map(unredaction => {
        const reqJson = getBorderJsonExample(borderResponse.data.environmentId, 'REQUEST', unredaction.method, unredaction.path);
        const resJson = getBorderJsonExample(borderResponse.data.environmentId, 'RESPONSE', unredaction.method, unredaction.path);
        const reqJsonObj = reqJson ? { reqJsonObj: JSON.parse(reqJson) } : {};
        const resJsonObj = resJson ? { resJsonObj: JSON.parse(resJson) } : {};
        return ({
          ...unredaction,
          ...reqJsonObj,
          ...resJsonObj,
          collections: unredaction.collections?.map(collection => {
            return {
              ...collection,
              strategies: (collection?.strategies || [])?.map(strat => {
                if (strat.strategy === UnredactionStrategyTypes.masking) {
                  return ({
                    ...strat,
                    strategyOptions: {
                      ...strat?.strategyOptions,
                      initialsLength: parseInt(strat?.strategyOptions?.initialsLength as unknown as string),
                      maskingLength: parseInt(strat?.strategyOptions?.maskingLength as unknown as string),
                    }
                  })
                }
                return ({ ...strat, strategy: strat?.strategy || UnredactionStrategyTypes.default })
              }),
              isErrorCorrectionFieldIdx: collection.strategies?.findIndex(stg => stg.isErrorCorrectionField)?.toString(),
            };
          }),
        })
      }),
    },
  };
};

export const defaultRedactionRuleFactory = (method: string, path: string, type: BorderPlugins, reqJsonObj?: { [key: string]: string; }, resJsonObj?: { [key: string]: string; }, globalEntityIdValue?: string) => {
  const initialStrategy = { path: '', strategy: DEFAULT_STRATEGY_TYPE, searchableKey: NOT_SEARCHABLE_KEY };

  return ({
    method,
    path,
    keyAliasModelExternalId: '',
    plugin: type,
    strategies: [initialStrategy],
    operationMode: BorderRuleOperationMode.processingAndStorage,
    globalEntityId: globalEntityIdValue,
    reqJsonObj,
    resJsonObj,
  })
}

export const extendedRedactionRuleFactory = (method: string, path: string, type: BorderPlugins, reqJsonObj?: { [key: string]: string; }, resJsonObj?: { [key: string]: string; }) => {
  const initialStrategy = { path: '', strategy: DEFAULT_STRATEGY_TYPE, searchableKey: NOT_SEARCHABLE_KEY };

  return ({
    method,
    path,
    plugin: type,
    reqJsonObj,
    resJsonObj,
    collections: [
      {
        collectionName: '',
        collectionPath: '',
        keyAliasModelExternalId: '',
        collectionResponsePath: '',
        entityIdPath: '',
        entityIdResponsePath: '',
        entityErrorCorrectionFieldPath: '',
        entityErrorCorrectionFieldResponsePath: '',
        strategies: [initialStrategy],
        operationMode: BorderRuleOperationMode.processingAndStorage,
      }
    ]
  })
}

export const searchRedactionRuleFactory = (method: string, path: string, type: BorderPlugins, reqJsonObj?: { [key: string]: string; }, resJsonObj?: { [key: string]: string; }, globalEntityIdValue?: string) => {
  const initialStrategy = { path: '', strategy: DEFAULT_STRATEGY_TYPE, searchableKey: NOT_SEARCHABLE_KEY };

  return ({
    method,
    path,
    plugin: type,
    keyAliasModelExternalId: '',
    strategies: [initialStrategy],
    operationMode: BorderRuleOperationMode.processingAndStorage,
    globalEntityId: globalEntityIdValue,
    reqJsonObj,
    resJsonObj,
    userBackendConfiguration: {
      defaultSearchLimit: 250,
      authType: BorderSearchAuthType.request,
      endpointType: BorderSearchEndpointType.search,
    },
    criteriaMapping: {
      in: BorderSearchCriteriaMappingIn.body,
      map: [{ key: '', value: '' }],
    }
  })
}

export const salesforceRedactionRuleFactory = (method: string, path: string, type: BorderPlugins, reqJsonObj?: { [key: string]: string; }, resJsonObj?: { [key: string]: string; }, globalEntityIdValue?: string) => {
  const initialStrategy = { path: '', strategy: DEFAULT_STRATEGY_TYPE, searchableKey: NOT_SEARCHABLE_KEY };

  return ({
    method,
    path,
    plugin: type,
    strategies: [initialStrategy],
    operationMode: BorderRuleOperationMode.processingAndStorage,
    globalEntityId: globalEntityIdValue,
    reqJsonObj,
    resJsonObj,
    recordsPath: 'records',
    allOrNoneFlagPath: 'allOrNone',
    externalIdPath: '',
    updateByExternalIdFlag: false,
    searchPath: '',
  })
}

const reqExampleJson = {
  "author_alias": "acdoyle",
  "email": "acdoyle@gmail.com",
  "first_name": "Arthur",
  "middle_name": "Conan",
  "last_name": "Doyle",
  "birthdate": "1859-05-22",
  "address": {
    "street": "221b, Baker street",
    "city": "London",
    "location": "Sherlock Holmes Shelter"
  }
}

const reqExampleExtended = {
  "books": [
    {
      "title": "The Hound of the Baskervilles",
      "language": "EN",
      "year": "1901",
      "authors": [
        {
          "first_name": "Arthur",
          "middle_name": "Conan",
          "last_name": "Doyle",
          "birthdate": "1859-05-22",
        },
      ]
    }
  ]
}

export const getBorderRuleJsonExample = (plugin: BorderPlugins, type: 'request' | 'response') => {
  switch (plugin) {
    case BorderPlugins.extended:
      return JSON.stringify(reqExampleExtended, null, 2);
    default:
      if (type === 'response') return JSON.stringify({ id: "988a076f-c5bc-4628-b8b0-232a0925c82e", ...reqExampleJson }, null, 2);
      return JSON.stringify(reqExampleJson, null, 2);
  }
}

export const Rule = styled.div`
    margin: 16px 0;
    `;

export const Collection = styled(Panel)`
    margin - bottom: 24px;
    `;

export const RuleType = styled.div`
    display: inline - block;
    margin - right: 16px;
    font - weight: 600;
    `;

export const UnredactionRuleHead = styled.div`
    display: grid;
    grid - template - columns: auto 0.1fr 1fr auto;
    column - gap: 8px;
    padding: ${({ theme }) => theme.spacing(2, 0)};
  > * {
    &: first - child {
      margin - top: 4px;
    }
    &: last - child {
      margin - top: 4px;
      margin - left: auto;
    }
  }
`;

export const renderSchemaOption = (environmentId: string) => (props: React.HTMLAttributes<HTMLLIElement>, option: FormSelectOptionInterface) => {
  if (option.value === CREATE_ALIAS_MODEL) {
    return (
      <StyledLink href={Routes.environment.aliases.list(environmentId)}>
        <Box mb={0} style={{ cursor: 'inherit' }} display='grid' gap={1} gridAutoFlow='column' {...props} onClick={undefined} fontSize='10px'>
          <AddIcon fontSize='small' /> <Typography>Add Schema Table</Typography>
        </Box>
      </StyledLink>
    )
  }
  return (
    <div {...props}>
      {option.label}
    </div>
  )
}

export const getSchemaOptions = (schema: ISchema[] = []) => [
  ({ value: CREATE_ALIAS_MODEL, label: CREATE_ALIAS_MODEL }),
  ({ value: USE_LEGACY_KEYS, label: USE_LEGACY_KEYS }),
  ...schema?.map((m) => ({ value: m.externalId, label: m.key })),
]

const StyledLink = styled(Link)`
  color: ${colors.text.primary}
`;
