import api from 'app/api/client';
import { CurrentUser } from 'app/api/models/CurrentUser';
import { ICountryDataV2 } from 'app/types/dashboard';
import { useRouter } from 'next/router';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { DATE_FROM_START, getTodayDateIsoStr, Routes } from '../constants';
import { errorHandler } from '../errors';
import { useSnackbarContext } from './SnackbarProvider';
import { EnvironmentResponseData } from 'app/types/environments';

interface IProps {
  children: JSX.Element | JSX.Element[];
  user?: CurrentUser;
}

interface UsageContextValue {
  fetching: boolean;
  data: ICountryDataV2[];
}

export const DEFAULT_VALUE: UsageContextValue = { data: [], fetching: true };

export const UsageContext = createContext<UsageContextValue>(DEFAULT_VALUE);

export const UsageProvider: FC<IProps> = ({ user, children }) => {

  const [data, setData] = useState<ICountryDataV2[]>([]);
  const [fetching, setFetching] = useState<boolean>(true);
  const { openSnackbar } = useSnackbarContext();
  const router = useRouter();

  useEffect(() => {
    if (!user) return;
    if (user?.defaultOrg?.removedAt) return;
    const getUsage = async () => {
      try {
        const usageRes = await api.fetchUsageByCompany(DATE_FROM_START, getTodayDateIsoStr());
        setData(usageRes)
      } catch (err) {
        errorHandler(err as Error, openSnackbar);
      } finally {
        setFetching(false);
      }
    };
    if (router.pathname !== Routes.company.deleted().toString()) {
      getUsage();
    }
  }, [api, openSnackbar, router?.pathname]);

  return <UsageContext.Provider value={{ data, fetching }}>{children}</UsageContext.Provider>;
};

export const useUsageContext = () => useContext(UsageContext);
